import i18n from "i18next";
import { initReactI18next } from "react-i18next";


// Importing translation files

import translationEN from "./locale/english.json";
import translationDE from "./locale/german.json";


//Creating object with the variables of imported translation files
const resources = {
    en: {
        translation: translationEN,
    },
    de: {
        translation: translationDE,
    },
};

//i18N Initialization
let language = "en";
if (localStorage.getItem("i18nextLng")) {
    language = localStorage.getItem("i18nextLng");
}

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: language,
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;