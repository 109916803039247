import React from "react";
import loading from "../assets/loading.svg";

const Loading = () => (
    <div className="row">
        <div className="col-sm-2 col-md-2 col-lg-2 text-center">
            <div className="spinner">
                <img src={loading} alt="Loading" width={200} />
            </div>
        </div>
    </div>
);

export default Loading;
