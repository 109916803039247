import iconNavigationMenu from "../assets/img/icons/navigation-menu.svg";
import iconClose from "../assets/img/icons/close.svg";
import React, {useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {Button, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, UncontrolledDropdown} from "reactstrap";
import {NavLink as RouterNavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";

const Header = () => {
    const {t} = useTranslation();
    const {
        user,
        isAuthenticated,
        loginWithRedirect,
        logout,
    } = useAuth0();
    const [source, setSource] = useState(false);

    const showNavbar = () => {
        const toggle = document.getElementById('scanner-cm-header-toggle-icon'),
            nav = document.getElementById('nav-bar'),
            body = document.getElementById('scanner-cm-body'),
            header = document.getElementById('header');

        // Validate that all variables exist
        if (toggle && nav && body && header) {
            // show navbar
            nav.classList.toggle('navbar-show')
            // change icon
            setSource(!source);
            // add padding to body
            body.classList.toggle('scanner-cm-body')
            // add padding to header
            header.classList.toggle('scanner-cm-body')
        }
    }

    const logoutWithRedirect = () =>
        logout({
            logoutParams: {
                returnTo: window.location.origin
            }
        });

    return (
        <>
            {!!isAuthenticated && (
                <header className="scanner-cm-header header" id="header">
                    <div className="scanner-cm-header-toggle">
                        <img src={source ? iconClose : iconNavigationMenu} alt="navigation icon" width="20" height="20"
                             id="scanner-cm-header-toggle-icon" onClick={showNavbar}/>
                    </div>
                    <Nav className="d-none d-md-block" navbar>
                        {!isAuthenticated && (
                            <NavItem>
                                <Button
                                    id="qsLoginBtn"
                                    color="primary"
                                    className="btn-margin"
                                    onClick={() => loginWithRedirect()}
                                >
                                    &nbsp;{t("Login")}
                                </Button>
                            </NavItem>
                        )}
                        {isAuthenticated && (
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret id="profileDropDown">
                                    <img
                                        src={user.picture}
                                        alt="Profile"
                                        className="nav-user-profile rounded-circle"
                                        width="50"
                                    />
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem header>{user.name}</DropdownItem>
                                    <DropdownItem
                                        tag={RouterNavLink}
                                        to="/profile"
                                        className="dropdown-profile"
                                    >
                                        <FontAwesomeIcon icon="user" className="mr-3"/>
                                        &nbsp; {t("Profile")}
                                    </DropdownItem>
                                    <DropdownItem
                                        id="qsLogoutBtn"
                                        onClick={() => logoutWithRedirect()}
                                    >
                                        <FontAwesomeIcon icon="power-off" className="mr-3"/>
                                        &nbsp; {t("Logout")}
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        )}
                    </Nav>
                    {!isAuthenticated && (
                        <Nav className="d-md-none" navbar>
                            <NavItem>
                                <Button
                                    id="qsLoginBtn"
                                    color="primary"
                                    block
                                    onClick={() => loginWithRedirect({})}
                                >
                                    &nbsp;{t("Login")}
                                </Button>
                            </NavItem>
                        </Nav>
                    )}
                    {isAuthenticated && (
                        <Nav
                            className="d-md-none justify-content-between"
                            navbar
                            style={{minHeight: 170}}
                        >
                            <NavItem>
                              <span className="user-info">
                                <img
                                    src={user.picture}
                                    alt="Profile"
                                    className="nav-user-profile d-inline-block rounded-circle mr-3"
                                    width="50"
                                />
                              </span>
                            </NavItem>
                        </Nav>
                    )}
                </header>
            )}
        </>
    )
}

export default Header;