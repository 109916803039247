import {getConfig} from "../config";

async function getMatingProtocolByItemId(itemId, token) {
    const res = await fetch(getConfig().url + `/api/item/` + itemId + `/mating-protocol`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type"  : "application/json",
            Authorization    : "BEARER " + token,
        },
    });

    return res.json();
}

async function deleteMatingById(itemId, observationId, token) {
    const res = await fetch(getConfig().url + `/api/item/` + itemId +`/mating-protocol/` + observationId + `/delete` , {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type"  : "application/json",
            Authorization    : "BEARER " + token,
        },
    });

    return res.json();
}

export {getMatingProtocolByItemId, deleteMatingById}