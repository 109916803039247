import React, {useState} from "react";
import {Container, Row, Col} from "reactstrap";
import Loading from "../components/Loading";
import {useAuth0, withAuthenticationRequired} from "@auth0/auth0-react";
import {useTranslation} from "react-i18next";
import {TextField, FormControl, InputLabel, Select, MenuItem} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import DialogCustom from "../components/DialogCustom";
import {deleteUserByUserId} from "../services/UserService";
import {toast} from "react-toastify";

export const ProfileComponent = ({auth0ManagementClient, changeLanguage}) => {
    const {user, logout, getAccessTokenSilently} = useAuth0();
    const {t, i18n} = useTranslation();
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const handleDeleteDialog = () => {
        setOpenDeleteDialog(true);
    };

    const handleDeleteClose = () => {
        setOpenDeleteDialog(false);
    };

    const deleteUser = async () => {
        const accessToken = await getAccessTokenSilently();
        let response = await deleteUserByUserId(user.sub, accessToken);
        if (response.status === 200) {
            logout({
                logoutParams: {
                    returnTo: window.location.origin
                }
            });
        } else {
            toast.error(t("Something went wrong, please try again later"));
        }
        handleDeleteClose();
    }

    return (
        <Container className="mb-5">
            <div className="card h-100">
                <div className="card-body">
                    <Row className="align-items-center profile-header mb-5 text-center text-md-left">
                        <Col md={2}>
                            <img
                                src={user.picture}
                                alt="Profile"
                                className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
                            />
                        </Col>
                        <Col md>
                            <h2>{user.name}</h2>
                            <p className="lead text-muted">{user.email}</p>
                        </Col>
                    </Row>
                    <Row>
                        <TextField disabled fullWidth id="nickname" label={t("Nickname")} value={user.nickname} />
                    </Row>
                    <Row className="mt-4">
                        <TextField disabled fullWidth id="name" label={t("Name")} value={user.name} />
                    </Row>
                    <Row className="mt-4">
                        <TextField disabled fullWidth id="email" label={t("Email")} value={user.email} />
                    </Row>
                    <Row className="mt-4">
                        <FormControl fullWidth>
                            <InputLabel id="changeLanguageLabel">{t("SelectLanguage")}</InputLabel>
                            <Select
                                labelId="changeLanguageLabel"
                                id="changeLanguageSelect"
                                value={i18n.language}
                                label={t("SelectLanguage")}
                                onChange={e => changeLanguage(e.target.value)}
                            >
                                <MenuItem value="de">Deutsch</MenuItem>
                                <MenuItem value="en">English</MenuItem>
                            </Select>
                        </FormControl>
                    </Row>
                    <Row className="mt-4">
                        <button
                            onClick={() => handleDeleteDialog()}
                            className="btn btn-secondary">
                            <FontAwesomeIcon
                                icon={faTrash}></FontAwesomeIcon>
                            &nbsp;{t('Delete Account')}
                        </button>
                    </Row>
                    <DialogCustom
                        handleClose={handleDeleteClose}
                        handleAction={deleteUser}
                        message={t("ToDeleteYourAccount")}
                        title={t("AreYouSure?")}
                        actionButton={t("Delete")}
                        cancelButton={t("Cancel")}
                        openDialog={openDeleteDialog}
                    />
                </div>
            </div>
        </Container>
    );
};

export default withAuthenticationRequired(ProfileComponent, {
    onRedirecting: () => <Loading/>
});
