import React from "react";
import logo from "../assets/img/spiderscanner-logo-big.svg";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Home = ({handleMessage}) => {
    const {t} = useTranslation();
    
    return (
        <div className="row">
            <h1 className="mb-3 mb-lg-5">Dashboard</h1>
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-body">
                        <h2>{t('Welcome')}</h2>
                        <p>{t('DashboardIntro')}</p>
                        <img src={logo} alt="spiderscanner logo" width={200} className="img-fluid" />
                    </div>
                </div>
            </div>
            <div className="col-sm-6 col-lg-4 mt-3">
                <div className="card">
                    <div className="card-body">
                        <h2>{t('StockList')}</h2>
                        <p>{t('DashboardStockListIntro')}</p>
                        <Link to="/items" className="btn btn-primary">{t('GoTo')} {t('StockList')}</Link>
                    </div>
                </div>
            </div>
            <div className="col-sm-6 col-lg-4 mt-3">
                <div className="card">
                    <div className="card-body">
                        <h2>{t('Label Generator')}</h2>
                        <p>{t('DashboardLabelIntro')}</p>
                        <Link to="/label" className="btn btn-primary">{t('GoTo')} {t('Label Generator')}</Link>
                    </div>
                </div>
            </div>
            <div className="col-sm-6 col-lg-4 mt-3">
                <div className="card">
                    <div className="card-body">
                        <h2>{t('Profile')}</h2>
                        <p>{t('ProfileIntro')}</p>
                        <Link to="/profile" className="btn btn-primary">{t('GoTo')} {t('Profile')}</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Home;
