import {Alert} from "@mui/material";
import React from "react";



const AlertCustom = ({severity, message}) => {
    function NewlineText({text}) {
        return text.split('\n').map(str => <div key={str}>{str}</div>);
    }
return (
    <Alert severity={severity} className="text-break">
        <NewlineText text={message} />
    </Alert>
);
}

export default AlertCustom;

