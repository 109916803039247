import {getConfig} from "../config";

async function getItemsByUserFromService(userId, token) {
    const res = await fetch(getConfig().url + `/api/items?userId=` + userId, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type"  : "application/json",
            Authorization    : "BEARER " + token,
        },
    });

    return res.json();
}

async function getItemByIdFromService(itemId, token) {
    const res = await fetch(getConfig().url + `/api/item/` + itemId, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type"  : "application/json",
            Authorization    : "BEARER " + token,
        },
    });

    return res.json();
}

async function deleteItemById(itemId, token) {
    const res = await fetch(getConfig().url + `/api/items/` + itemId +`/delete` , {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type"  : "application/json",
            Authorization    : "BEARER " + token,
        },
    });

    return res.json();
}

async function deleteAllByUserId(userId, token) {
    const res = await fetch(getConfig().url + `/api/items/delete-all` , {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type"  : "application/json",
            Authorization    : "BEARER " + token,
        },
        body : JSON.stringify({ "userId" : userId })
    });

    return res.json();
}

async function saveItemToDb(data, token) {
    const res = await fetch(getConfig().url + `/api/items`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type"  : "application/json",
            Authorization    : "BEARER " + token,
        },
        body : JSON.stringify(data)
    });

    return res.json();
}

async function updateItem(data, token) {
    const res = await fetch(getConfig().url + `/api/item/` + data.id, {
        method: "PATCH",
        headers: {
            Accept: "application/json",
            "Content-Type"  : "application/json",
            Authorization    : "BEARER " + token,
        },
        body : JSON.stringify(data)
    });

    return res.json();
}

export {getItemsByUserFromService, deleteItemById, getItemByIdFromService, saveItemToDb, updateItem, deleteAllByUserId}