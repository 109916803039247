import React, {Fragment, useRef, useState} from "react";
import {TextField, Tooltip} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBackward, faPlus} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import AlertCustom from "../components/AlertCustom";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {saveItemToDb} from "../services/ItemService";
import {useAuth0} from "@auth0/auth0-react";
import {Row, Col} from "reactstrap";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

const ItemsNew = () => {
    const [inputErrors, setInputErrors] = useState(false);
    const [ageMonth, setAgeMonth] = useState(null);
    const [ageYear, setAgeYear] = useState(null);
    const [inputErrorsMessage, setInputErrorsMessage] = useState();
    const nameInputRef = useRef();
    const commonNameInputRef = useRef();
    const amountMalesInputRef = useRef();
    const amountFemalesInputRef = useRef();
    const amountUnsexedInputRef = useRef();
    const ageMonthInputRef = useRef();
    const ageYearInputRef = useRef();
    const {t} = useTranslation();
    const {user, getAccessTokenSilently} = useAuth0();
    const navigate = useNavigate();

    const submitForm = async () => {
        setInputErrors(false);
        setInputErrorsMessage(null);
        let nameValue = nameInputRef.current.value.trim();
        let commonName = commonNameInputRef.current.value.trim();
        let amountMalesValue = amountMalesInputRef.current.value;
        let amountFemalesValue = amountFemalesInputRef.current.value;
        let amountUnsexedValue = amountUnsexedInputRef.current.value;
        const accessToken = await getAccessTokenSilently();
        let validationErrors = [];

        if (nameValue === "")
        {
            validationErrors.push(t("NameCantBeEmpty"))
        }

        if (typeof amountMalesValue === 'undefined') {
            validationErrors.push(t("AmountMalesCantBe0"))
        }

        if (typeof amountFemalesValue === 'undefined') {
            validationErrors.push(t("AmountFemalesCantBe0"))
        }

        if (typeof amountUnsexedValue === 'undefined') {
            validationErrors.push(t("AmountUnsexedCantBe0"))
        }

        if (parseInt(amountFemalesValue) === 0 && parseInt(amountMalesValue) === 0 &&  parseInt(amountUnsexedValue) === 0) {
            validationErrors.push(t("ChooseAtLeastOneOfaGender"))
        }

        if (validationErrors.length >= 1) {
            setInputErrors(true);
            let errorMessage = "";
            validationErrors.forEach(function (value) {
                errorMessage += value + "\n";
            })
            setInputErrorsMessage(errorMessage);
            return false;
        }

        let data = {
            "userId" : user.sub.split("|")[1],
            "name" : nameValue,
            "commonName" : commonName,
            "amountMales" : parseInt(amountMalesValue),
            "amountFemales" : parseInt(amountFemalesValue),
            "amountUnknown" : parseInt(amountUnsexedValue),
            "ageMonth" : ageMonth != null ? ageMonth.month() + 1 : 1,
            "ageYear" : ageYear != null ? ageYear.year() : 1970,
        }

        let result = await saveItemToDb(data, accessToken);

        if (result.status === 200) {
           navigate('/items');
        }
        if (result?.status === 400) {
            toast.error(t("Something went wrong, please try again later"));
        }
    }

    const renderItems = () => {
        return (
            <div className="row">
                <h1 className="mb-3 mb-lg-5">{t("AddNewItem")}</h1>
                <div className="mb-3 mb-lg-5">
                    <div className="card h-100">
                        <div className="card-body">
                            {inputErrors ?
                                (<>
                                        <Row className="mb-4">
                                            <AlertCustom severity="error" message={inputErrorsMessage} />
                                        </Row>
                                </>
                                ): null}
                                <>
                                    <div>
                                        <Row>
                                            <TextField required inputRef={nameInputRef} fullWidth id="item-name" label={t("Name")} />
                                        </Row>
                                        <Row className="mt-4">
                                            <TextField fullWidth inputRef={commonNameInputRef} id="item-common_name" label={t("Common") + " "+ t("Name")}  />
                                        </Row>
                                        <Row className="mt-4">
                                            <TextField
                                                required
                                                fullWidth
                                                type="number"
                                                id="item-amount_males"
                                                label={t("Amount") + " "+ t("Males")}
                                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                                defaultValue={0}
                                                InputLabelProps={{shrink: true}}
                                                inputRef={amountMalesInputRef} />
                                        </Row>
                                        <Row className="mt-4">
                                            <TextField
                                                required
                                                fullWidth
                                                type="number"
                                                id="item-amount_females"
                                                label={t("Amount") + " "+ t("Females")}
                                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                                defaultValue={0}
                                                InputLabelProps={{shrink: true}}
                                                inputRef={amountFemalesInputRef} />
                                        </Row>
                                        <Row className="mt-4">
                                            <TextField
                                                required
                                                fullWidth
                                                type="number"
                                                id="item-amount_unsexed"
                                                label={t("Amount") + " "+ t("Unsexed")}
                                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                                defaultValue={0}
                                                InputLabelProps={{shrink: true}}
                                                inputRef={amountUnsexedInputRef} />
                                        </Row>
                                        <Row className="mt-4">
                                        <DatePicker
                                                    textField={(props) => <TextField {...props}  />}
                                                    label={t("AgeMonth")}
                                                    views={['month']}
                                                    onChange={(newValue) => setAgeMonth(newValue)}
                                                    ref={ageMonthInputRef}
                                        />
                                        &nbsp;
                                        <DatePicker
                                                    textField={(props) => <TextField {...props} />}
                                                    label={t("AgeYear")}
                                                    views={['year']}
                                                    onChange={(newValue) => setAgeYear(newValue)}
                                                    ref={ageYearInputRef}
                                        />
                                        </Row>
                                        <Row className="mt-4 row-cols-lg-auto  row-cols-auto align-items-center">
                                            <Col>
                                                <Tooltip title={t("Create")} disableFocusListener>
                                                <button className="btn btn-primary" onClick={() => submitForm()}>
                                                    <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                                                </button></Tooltip>
                                            </Col>
                                            <Col>
                                                <Tooltip title={t("Back")} disableFocusListener>
                                                    <button className="btn btn-secondary" onClick={() => navigate("/items")}>
                                                        <FontAwesomeIcon icon={faBackward}></FontAwesomeIcon>
                                                    </button>
                                                </Tooltip>
                                            </Col>

                                        </Row>
                                    </div>
                                </>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <Fragment>
            {renderItems()}
        </Fragment>
    );


}

export default ItemsNew;