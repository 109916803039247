import {useAuth0} from "@auth0/auth0-react";
import React from "react";
import {NavItem} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHammer, faHouse, faList} from '@fortawesome/free-solid-svg-icons'

import {Link, NavLink as RouterNavLink} from "react-router-dom";
import logo from "../../src/assets/img/spiderscanner-logo-white.svg";
import {useTranslation} from "react-i18next";
import {faTags} from "@fortawesome/free-solid-svg-icons/faTags";


const Sidebar = () => {
    const { isAuthenticated } = useAuth0();
    const {t} = useTranslation();
    const {
        user,
        logout,
    } = useAuth0();

    const logoutWithRedirect = () =>
            logout({
                logoutParams: {
                    returnTo: window.location.origin
                }
            });

    const roles = user['https://myroles.com/roles'];

    let isAdmin = false;
    if (roles.find((element) => element === "Admin")) {
        isAdmin = true;
    }

    return isAuthenticated ? (
        <div className="scanner-cm-sidebar navbar" id="nav-bar">
            <nav className="nav">
                <div>
                    <a href="/" className="navbar-brand">
                        <span className=""><img src={logo} alt="Logo" title="" width="130" /></span>
                    </a>
                    <div>
                        <NavItem>
                            <Link to="/" className="nav-link">
                                <FontAwesomeIcon icon={faHouse} className="mr-3"/>
                                <span className="nav_name">{t('Dashboard')}</span>
                            </Link>
                        </NavItem>
                        <NavItem>
                            <Link to="/items" className="nav-link">
                                <FontAwesomeIcon icon={faList} className="mr-3"/>
                                <span className="nav_name">{t('StockList')}</span>
                            </Link>
                        </NavItem>
                        <NavItem>
                            <Link to="/label" className="nav-link">
                                <FontAwesomeIcon icon={faTags} className="mr-3"/>
                                <span className="nav_name">{t('Label Generator')}</span>
                            </Link>
                        </NavItem>
                        <NavItem>
                            <RouterNavLink className="nav-link d-md-none d-lg-none"
                                to="/profile"
                            >
                                <FontAwesomeIcon icon="user" className="mr-3" />
                                <span className="nav_name">{t('Profile')}</span>
                            </RouterNavLink>
                        </NavItem>
                        <NavItem>
                            <RouterNavLink className="nav-link d-md-none d-lg-none"
                                to="#"
                                id="qsLogoutBtn"
                                onClick={() => logoutWithRedirect()}
                            >
                                <FontAwesomeIcon icon="power-off" className="mr-3"/>
                                <span className="nav_name">{t('Logout')}</span>
                            </RouterNavLink>
                        </NavItem>

                        {isAdmin ?
                            <NavItem>
                                <Link to="/admin" className="nav-link">
                                    <FontAwesomeIcon icon={faHammer} className="mr-3"/>
                                    <span className="nav_name">{t('Admin')}</span>
                                </Link>
                            </NavItem>
                            : null}
                    </div>
                </div>
            </nav>
        </div>
    ) : null;
}

export default Sidebar;