import React from 'react';
import {Page, View, Document, StyleSheet, Image, Text} from '@react-pdf/renderer';
import BgImage from "../../assets/img/spider-for-bg.png";
import * as canvg from 'canvg';

const styles = StyleSheet.create({
    page: { backgroundColor: 'white', padding: 5 },
    section: {
        textAlign: 'center',
        borderColor : '#cccccc',
        border: 2,
        borderRadius: 9,
        opacity: 0.3
    },
    images: {
        height: "45mm", zIndex: 1, opacity: 0.2, objectFit: 'cover'
    },
    text: {
        opacity : 1, fontSize : "15px"
    },
    row: {
        flex: 1,
        flexDirection: 'row',
        flexGrow: 1,
        flexShrink : 1,
        margin : 0

    },
    left: {
        flexShrink: 1,
        width: "80mm",
        height: "40mm",
        margin: 30,
        flexGrow: 0,

    },
    right: {
        padding: 5,
        width: "80mm",
        height: "40mm",
        flexShrink: 1,
        flexGrow: 0,
        margin: 30,
    },
});

const PDF = ({species, options, commonNameCheck, ageCheck, genderCheck}) => {
    const svgToDataUri = async (svgString: string) => {
        try {
            const canvas: HTMLCanvasElement = document.createElement("canvas")
            const context = canvas.getContext("2d")
            if (context) {
                const v = canvg.Canvg.fromString(context, svgString.trim())
                await v.render()
                return canvas.toDataURL("image/png", 100)
            }
        } catch (error) {
            console.error("Error occured:", error)
            return ""
        }
    }
    const generateFontSizeLabel = (value) => {
        let fontSize = "18px";
        if (value.length > 20) {
            fontSize = "14px";
        }
        if (value.length > 30) {
            fontSize = "13px";
        }
        return fontSize;
    }
    const generateFontSizeLabelCommonName = (value) => {
        let fontSize = "14px";
        if (value.length > 20) {
            fontSize = "12px";
        }
        if (value.length > 30) {
            fontSize = "6px";
        }
        return fontSize;
    }
    const groupForOutput = () => {
        let group = [];
        let pos = 0;
        while (pos < species.length) {
            group.push(species.slice(pos, pos + 2));
            pos += 2;
        }
        return group;
    }

    const renderSingleDocument = (svgDataUri, fontSizeLabel, commonNameSizeLabel, speciesAnimal) => {
        return (
            <Document title="Label">
                <Page key="single" size="A4" style={[styles.page, ]}>
                    <View key={"single-sub-"} style={[styles.row, ]}>
                        <View key={"single-sub-1"} style={[styles.left, styles.section]}>
                            <Image key={"img-1"} src={BgImage} style={[styles.images, { }]} />
                            <Image key={"img-2"} src={svgDataUri} style={[styles.images, { opacity: 1, position : "absolute", left : -15, top: 35, maxWidth : "50%", maxHeight : "50%"}]} />
                            <Text key={"text-1"} style={[styles.text, { opacity: 1, position : "absolute", right : 5, top: 5, fontSize : fontSizeLabel}]} >{speciesAnimal?.label}</Text>
                            {species?.commonName !== "-" && !commonNameCheck ? <Text style={[styles.text, { opacity: 1, position : "absolute", right : 5, top: 25, fontSize : commonNameSizeLabel}]} >{speciesAnimal?.commonName}</Text> : null}
                            {!genderCheck ? <Text key={"text-2"} style={[styles.text, { opacity: 1, position : "absolute", right : 5, top: 55 }]} >{speciesAnimal?.genders}</Text> : null}
                            {!ageCheck ? <Text key={"text-3"} style={[styles.text, { opacity: 1, position : "absolute", right : 5, top: 75 }]} >{speciesAnimal?.age}</Text> : null}
                        </View>
                    </View>
                </Page>
            </Document>
        );
    }

    const renderInsideOfRow = (i, placeOnPage, options, species) =>  {
        const svg = document.getElementById(options[species]?.id);
        const serializer = new XMLSerializer();
        const svgStr = serializer.serializeToString(svg);
        const svgDataUri = svgToDataUri(svgStr);
        let fontSizeLabel = generateFontSizeLabel(options[species]?.label);
        let commonNameSizeLabel = generateFontSizeLabelCommonName(options[species]?.commonName);

        return (
            <View key={"sub"+ i} style={[placeOnPage, styles.section]}>
                <Image src={BgImage} style={[styles.images, { }]} />
                <Image src={svgDataUri} style={[styles.images, { opacity: 1, position : "absolute", left : -15, top: 35, maxWidth : "50%", maxHeight : "50%"}]} />
                <Text style={[styles.text, { opacity: 1, position : "absolute", right : 5, top: 5, fontSize : fontSizeLabel}]} >{options[species]?.label}</Text>
                {species?.commonName !== "-" && !commonNameCheck ? <Text style={[styles.text, { opacity: 1, position : "absolute", right : 5, top: 25, fontSize : commonNameSizeLabel}]} >{options[species]?.commonName}</Text> : null}
                {!genderCheck ? <Text style={[styles.text, { opacity: 1, position : "absolute", right : 5, top: 55 }]} >{options[species]?.genders}</Text> : null}
                {!ageCheck ? <Text style={[styles.text, { opacity: 1, position : "absolute", right : 5, top: 75 }]} >{options[species]?.age}</Text> : null}
            </View>
        )
    }

    if (typeof species != "undefined" && species.length > 1) {
        let group = groupForOutput();

        return (
            <Document title="Labels">
                <Page key="multi" size="A4" style={[styles.page, ]}>
                    {group.map((group, i) => {
                        return (
                            <View key={"main" + i} style={[styles.row, ]}>
                                {group[0] ? renderInsideOfRow(i, styles.left, options, group[0]) : null}
                                {group[1] ? renderInsideOfRow(i, styles.right, options, group[1]) : null}
                            </View>
                            )
                    })}
                </Page>
            </Document>
        );
    } else {
        const svg = document.getElementById(options[species]?.id);
        const serializer = new XMLSerializer();
        const svgStr = serializer.serializeToString(svg);
        const svgDataUri = svgToDataUri(svgStr);

        let fontSizeLabel = generateFontSizeLabel(options[species]?.label);
        let commonNameSizeLabel = generateFontSizeLabelCommonName(options[species]?.commonName);

        return renderSingleDocument(svgDataUri, fontSizeLabel, commonNameSizeLabel, options[species]);
    }
}
export default PDF;