import React, {Fragment, useEffect, useRef, useState} from "react";
import {
    Skeleton, TextField, Tooltip
} from "@mui/material";
import {Row, Col} from "reactstrap";
import {useAuth0} from "@auth0/auth0-react";
import {useTranslation} from "react-i18next";
import {getItemByIdFromService, updateItem} from "../services/ItemService";
import {useNavigate, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBackward, faFloppyDisk} from "@fortawesome/free-solid-svg-icons";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import AlertCustom from "../components/AlertCustom";
import dayjs from "dayjs";
import {toast} from "react-toastify";

const ItemsEdit = () => {
    const [inputErrors, setInputErrors] = useState(false);
    const [inputErrorsMessage, setInputErrorsMessage] = useState();
    const [loading, setLoading] = useState(true);
    const [item, setItem] = useState();
    const [ageMonth, setAgeMonth] = useState(null);
    const [ageYear, setAgeYear] = useState(null);
    const nameInputRef = useRef();
    const commonNameInputRef = useRef();
    const amountMalesInputRef = useRef();
    const amountFemalesInputRef = useRef();
    const amountUnsexedInputRef = useRef();
    const {getAccessTokenSilently} = useAuth0();
    const {t} = useTranslation();
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const accessToken = await getAccessTokenSilently();
            let itemFromResponse = await getItemByIdFromService(params.id, accessToken);
            if (itemFromResponse?.item) {
                setItem(itemFromResponse?.item);
                setAgeMonth(dayjs().set('month', itemFromResponse?.item.age_month -1))
                setAgeYear(dayjs().set('years', itemFromResponse?.item.age_year))
                setLoading(false);
            }
        }

        fetchData().catch(r => {
            console.log(r.message)
            setLoading(false)
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const submitForm = async () => {
        setInputErrors(false);
        setInputErrorsMessage(null);
        let nameValue = nameInputRef.current.value.trim();
        let commonName = commonNameInputRef.current.value.trim();
        let amountMalesValue = amountMalesInputRef.current.value;
        let amountFemalesValue = amountFemalesInputRef.current.value;
        let amountUnsexedValue = amountUnsexedInputRef.current.value;
        const accessToken = await getAccessTokenSilently();

        let validationErrors = [];

        if (nameValue === "")
        {
            validationErrors.push(t("NameCantBeEmpty"))
        }

        if (typeof amountMalesValue === 'undefined') {
            validationErrors.push(t("AmountMalesCantBe0"))
        }

        if (typeof amountFemalesValue === 'undefined') {
            validationErrors.push(t("AmountFemalesCantBe0"))
        }

        if (typeof amountUnsexedValue === 'undefined') {
            validationErrors.push(t("AmountUnsexedCantBe0"))
        }

        if (ageMonth === null) {
            validationErrors.push(t("AgeMonthCantBe0"))
        }

        if (ageYear === null) {
            validationErrors.push(t("AgeYearCantBe0"))
        }

        if (parseInt(amountFemalesValue) === 0 && parseInt(amountMalesValue) === 0 &&  parseInt(amountUnsexedValue) === 0) {
            validationErrors.push(t("ChooseAtLeastOneOfaGender"))
        }

        if (validationErrors.length >= 1) {
            setInputErrors(true);
            let errorMessage = "";
            validationErrors.forEach(function (value) {
                errorMessage += value + "\n";
            })
            setInputErrorsMessage(errorMessage);
            return false;
        }

        let data = {
            "id" : params.id,
            "name" : nameValue,
            "commonName" : commonName,
            "amountMales" : parseInt(amountMalesValue),
            "amountFemales" : parseInt(amountFemalesValue),
            "amountUnknown" : parseInt(amountUnsexedValue),
            "ageMonth" : ageMonth.month() + 1,
            "ageYear" : ageYear.year(),
        }

        let result = await updateItem(data, accessToken);

        if (result.status === 200) {
            navigate('/items');
        }
        if (result?.status === 400) {
            toast.error(t("Something went wrong, please try again later"));
        }
    }

    const redirectBack = () => {
        const queryParameters = new URLSearchParams(window.location.search)
        const referer = queryParameters.get("referer")
        if (referer === "show") {
            navigate('/items/' + params.id + '/show');
        } else {
            navigate('/items/');
        }
    };
    const renderItems = () => {
        let months = dayjs().set('month', item?.age_month -1);
        let years = dayjs().set('years', item?.age_year);
        return (
            <div className="row">
                <h1 className="mb-3 mb-lg-5">{t("ItemEdit")}</h1>
                <div className="mb-3 mb-lg-5">
                    <div className="card h-100">
                        <div className="card-body">
                            {loading ?
                                <>
                                    <Skeleton animation="wave" />
                                    <Skeleton animation="wave" />
                                    <Skeleton animation="wave" />
                                    <Skeleton animation="wave" />
                                    <Skeleton animation="wave" />
                                    <Skeleton animation="wave" />
                                    <Skeleton animation="wave" />
                                </>
                                : null}
                            {!loading ?
                                <>
                                    {inputErrors ?
                                        (<>
                                                <Row className="mb-4">
                                                    <AlertCustom severity="error" message={inputErrorsMessage} />
                                                </Row>
                                            </>
                                        ): null}
                                    <div>
                                        <div>
                                            <Row>
                                                <TextField required inputRef={nameInputRef} fullWidth id="item-name" label={t("Name")} defaultValue={item?.name} />
                                            </Row>
                                            <Row className="mt-4">
                                                <TextField required fullWidth inputRef={commonNameInputRef} id="item-common_name" label={t("Common") + " "+ t("Name")} defaultValue={item?.common_name} />
                                            </Row>
                                            <Row className="mt-4">
                                                <TextField
                                                    required
                                                    fullWidth
                                                    type="number"
                                                    id="item-amount_males"
                                                    label={t("Amount") + " "+ t("Males")}
                                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                                    defaultValue={item?.amount_males}
                                                    InputLabelProps={{shrink: true}}
                                                    inputRef={amountMalesInputRef} />
                                            </Row>
                                            <Row className="mt-4">
                                                <TextField
                                                    required
                                                    fullWidth
                                                    type="number"
                                                    id="item-amount_females"
                                                    label={t("Amount") + " "+ t("Females")}
                                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                                    defaultValue={item?.amount_females}
                                                    InputLabelProps={{shrink: true}}
                                                    inputRef={amountFemalesInputRef} />
                                            </Row>
                                            <Row className="mt-4">
                                                <TextField
                                                    required
                                                    fullWidth
                                                    type="number"
                                                    id="item-amount_unsexed"
                                                    label={t("Amount") + " "+ t("Unsexed")}
                                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                                    defaultValue={item?.amount_unknown}
                                                    InputLabelProps={{shrink: true}}
                                                    inputRef={amountUnsexedInputRef} />
                                            </Row>
                                            <Row className="mt-4">
                                                <DatePicker
                                                    textField={(props) => <TextField {...props} />}
                                                    label={t("AgeMonth")}
                                                    views={['month']}
                                                    defaultValue={months}
                                                    onChange={(newValue) => setAgeMonth(newValue)}
                                                />
                                                &nbsp;
                                                <DatePicker
                                                    textField={(props) => <TextField {...props} />}
                                                    label={t("AgeYear")}
                                                    views={['year']}
                                                    onChange={(newValue) => setAgeYear(newValue)}
                                                    defaultValue={years}

                                                />
                                            </Row>
                                            <Row className="mt-4 row-cols-lg-auto row-cols-auto align-items-center">
                                                <Col>
                                                    <Tooltip title={t("Save")} disableFocusListener>
                                                        <button className="btn btn-primary" onClick={() => submitForm()}>
                                                            <FontAwesomeIcon icon={faFloppyDisk}></FontAwesomeIcon>
                                                        </button>
                                                    </Tooltip>
                                                </Col>
                                                <Col>
                                                    <Tooltip title={t("Back")} disableFocusListener>
                                                        <button className="btn btn-secondary" onClick={redirectBack}>
                                                            <FontAwesomeIcon icon={faBackward}></FontAwesomeIcon>
                                                        </button>
                                                    </Tooltip>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </>
                            : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <Fragment>
            {renderItems()}
        </Fragment>
    );


}

export default ItemsEdit;