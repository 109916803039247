import React from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";

import {Container} from "reactstrap";

import Home from "./views/Home";
import "./assets/css/index.scss";
import Profile from "./views/Profile";
import {useAuth0} from "@auth0/auth0-react";
import history from "./utils/history";
import {useTranslation} from "react-i18next";

// styles
import "./assets/css/App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import Login from "./components/Login";
import Items from "./views/Items";
import ItemsEdit from "./views/ItemsEdit";
import ItemsNew from "./views/ItemsNew";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import ItemShow from "./views/ItemShow";
import ProtocolNew from "./views/ProtocolNew";
import NotFound from "./views/NotFound";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ItemsImport from "./views/ItemsImport";
import Label from "./views/Label";
import AdminHome from "./views/AdminHome";
import AdminUser from "./views/AdminUser";
import {ErrorBoundary} from "react-error-boundary";
import Error from "./components/Error";

const App = () => {
    initFontAwesome();
    const {isAuthenticated, error} = useAuth0();
    const {i18n} = useTranslation();
    document.body.dir = i18n.dir();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng)
        document.body.dir = i18n.dir();
        localStorage.setItem("i18nextLng", lng);
    }

    if (error) {
        return <Error error={error} />;
    }

    if (!isAuthenticated) {
        return (
            <Router history={history}>
                <Login type="login"/>
            </Router>
        )
    } else {
        return (
            <ErrorBoundary fallback={<Error />}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Router history={history}>
                        <Header/>
                        <Sidebar/>
                        <main className="scanner-cm-main container-fluid min-height-100vh">
                            <ToastContainer
                                position="top-right"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="colored"
                            />
                            <Container className="flex-grow-1 mt-5">
                                <Routes>
                                    <Route path="/" exact element={<Home/>}/>
                                    <Route path="/items" exact element={<Items/>}/>
                                    <Route path="/items/:id/edit" exact element={<ItemsEdit/>}/>
                                    <Route path="/items/:id/show" exact element={<ItemShow/>}/>
                                    <Route path="/items/new" exact element={<ItemsNew/>}/>
                                    <Route path="/items/import" exact element={<ItemsImport/>}/>
                                    <Route path="/items/:id/feeding-protocol" exact
                                           element={<ProtocolNew type="Feeding"/>}/>
                                    <Route path="/items/:id/observation-protocol" exact
                                           element={<ProtocolNew type="Observation"/>}/>
                                    <Route path="/items/:id/mating-protocol" exact
                                           element={<ProtocolNew type="Mating"/>}/>
                                    <Route path="/items/:id/additional" exact
                                           element={<ProtocolNew type="Additional"/>}/>
                                    <Route path="/label" exact element={<Label/>}/>
                                    <Route path="/profile" element={<Profile changeLanguage={changeLanguage}/>}/>
                                    {/* Admin area */}
                                    <Route path="/admin" exact element={<AdminHome/>}/>
                                    <Route path="/admin/users" exact element={<AdminUser/>}/>
                                    <Route path="*" element={<NotFound/>}>
                                    </Route>
                                </Routes>
                            </Container>
                        </main>
                    </Router>
                </LocalizationProvider>
            </ErrorBoundary>
        );
    }
};

export default App;
