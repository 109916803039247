import logo from "../../src/assets/img/spiderscanner-logo-big.svg";
import React from "react";
import {useTranslation} from "react-i18next";

const Error = ({error}) => {
    const {t} = useTranslation();

    return (
        <>
            <div className="row text-center height-100vh justify-content-center align-items-center display-flex scanner-cm-main-rowLogin">
                <div className="col-sm-10 col-md-8 col-lg-6 text-center ">
                    <div className="card border-top-big">
                        <div className="card-body my-5">
                            <img src={logo} alt="" title="" width="350" className="img-fluid d-block mx-auto" />
                            <div className="mb-3">
                                <h3>{ t("Something went wrong!") }</h3>
                            </div>
                            <div className="">
                                <p className="mt-1">{error ? error.message : t("Check console for more details")}</p>
                                <button className="btn btn-primary mt-1"
                                        onClick={() => window.location = "/"}>{t("GoTo")} {t("Dashboard")}</button> &nbsp;
                                <button className="btn btn-primary mt-1"
                                        onClick={() => window.location = "/items"}>{t("GoTo")} {t("StockList")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Error;