import React, {Fragment, useRef, useState} from "react";
import {TextField, Tooltip} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBackward, faPlus} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import AlertCustom from "../components/AlertCustom";
import {useAuth0} from "@auth0/auth0-react";
import {Row, Col, Label} from "reactstrap";
import {useNavigate, useParams} from "react-router-dom";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import Switch from '@mui/material/Switch';
import {saveItemToDbByType} from "../services/ProtocolService";
import dayjs from "dayjs";
import {toast} from "react-toastify";

const ProtocolNew = ({type}) => {
    const [inputErrors, setInputErrors] = useState(false);
    const [inputErrorsMessage, setInputErrorsMessage] = useState();
    const {t} = useTranslation();
    const {user, getAccessTokenSilently} = useAuth0();
    const params = useParams();
    const foodRef = useRef();
    const ObservationCommentRef = useRef();
    const MatingCommentRef = useRef();
    const AdditionalCommentRef = useRef();
    const [feedingDate, setFeedingDate] = useState(dayjs());
    const [observationDate, setObservationDate] = useState(dayjs());
    const [additionalDate, setAdditionalDate] = useState(dayjs());
    const [matingDate, setMatingDate] = useState(dayjs());
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();

    const submitFeedingForm = async () => {
        setInputErrors(false);
        setInputErrorsMessage(null);
        const accessToken = await getAccessTokenSilently();

        let validationErrors = [];

        if (feedingDate === null) {
            validationErrors.push(t("FeedingDateCant0"))
        }

        if (foodRef.current.value === "") {
            validationErrors.push(t("FoodCantBeEmpty"))
        }

        if (validationErrors.length >= 1) {
            setInputErrors(true);
            let errorMessage = "";
            validationErrors.forEach(function (value) {
                errorMessage += value + "\n";
            })
            setInputErrorsMessage(errorMessage);
            return false;
        }

        let data = {
            "userId" : user.sub.split("|")[1],
            "itemId" : params.id,
            "feedingDate" : feedingDate.toLocaleString(),
            "food" : foodRef.current.value,
            "successful" : success,
        }

        let result = await saveItemToDbByType("feeding_protocol", data, accessToken);
        if (result.status === 200) {
            navigate(`/items/${params.id}/show`);
        }
    }
    const submitObservationForm = async () => {
        setInputErrors(false);
        setInputErrorsMessage(null);
        const accessToken = await getAccessTokenSilently();

        let validationErrors = [];

        if (observationDate === null) {
            validationErrors.push(t("ObservationDateCant0"))
        }

        if (ObservationCommentRef.current.value === "") {
            validationErrors.push(t("CommentCantBeEmpty"))
        }

        if (validationErrors.length >= 1) {
            setInputErrors(true);
            let errorMessage = "";
            validationErrors.forEach(function (value) {
                errorMessage += value + "\n";
            })
            setInputErrorsMessage(errorMessage);
            return false;
        }

        let data = {
            "userId" : user.sub.split("|")[1],
            "itemId" : params.id,
            "observationDate" : observationDate.toLocaleString(),
            "comment" : ObservationCommentRef.current.value
        }

        let result = await saveItemToDbByType("observation_protocol", data, accessToken);
        if (result.status === 200) {
            navigate(`/items/${params.id}/show`);
        }
    }
    const submitMatingForm = async () => {
        setInputErrors(false);
        setInputErrorsMessage(null);
        const accessToken = await getAccessTokenSilently();

        let validationErrors = [];

        if (matingDate === null) {
            validationErrors.push(t("FeedingDateCant0"))
        }

        if (MatingCommentRef.current.value === "") {
            validationErrors.push(t("CommentCantBeEmpty"))
        }

        if (validationErrors.length >= 1) {
            setInputErrors(true);
            let errorMessage = "";
            validationErrors.forEach(function (value) {
                errorMessage += value + "\n";
            })
            setInputErrorsMessage(errorMessage);
            return false;
        }

        let data = {
            "userId" : user.sub.split("|")[1],
            "itemId" : params.id,
            "matingDate" : feedingDate.toLocaleString(),
            "comment" : MatingCommentRef.current.value,
            "successful" : success,
        }

        let result = await saveItemToDbByType("mating_protocol", data, accessToken);
        if (result.status === 200) {
            navigate(`/items/${params.id}/show`);
        }
    }
    const submitAdditionalForm = async () => {
        setInputErrors(false);
        setInputErrorsMessage(null);
        const accessToken = await getAccessTokenSilently();

        let validationErrors = [];

        if (additionalDate === null) {
            validationErrors.push(t("ObservationDateCant0"))
        }

        if (AdditionalCommentRef.current.value === "") {
            validationErrors.push(t("CommentCantBeEmpty"))
        }

        if (validationErrors.length >= 1) {
            setInputErrors(true);
            let errorMessage = "";
            validationErrors.forEach(function (value) {
                errorMessage += value + "\n";
            })
            setInputErrorsMessage(errorMessage);
            return false;
        }

        let data = {
            "userId" : user.sub.split("|")[1],
            "itemId" : params.id,
            "additionalDate" : additionalDate.toLocaleString(),
            "comment" : AdditionalCommentRef.current.value
        }

        let result = await saveItemToDbByType("additional", data, accessToken);
        if (result.status === 200) {
            navigate(`/items/${params.id}/show`);
        }
        if (result?.status === 400) {
            toast.error(t("Something went wrong, please try again later"));
        }
    }
    const renderItems = () => {
        return (
            <div className="row">
                <h1 className="mb-3 mb-lg-5">
                    {type === 'Feeding' ? t("AddNewFeedingProtocol") : null}
                    {type === 'Observation' ? t("AddNewObservationProtocol") : null}
                    {type === 'Mating' ? t("AddNewMatingProtocol") : null}
                    {type === 'Additional' ? t("AddNewAdditional") : null}
                </h1>
                <div className="mb-3 mb-lg-5">
                    <div className="card h-100">
                        <div className="card-body">
                            {inputErrors ?
                                (<>
                                        <Row className="mb-4">
                                            <AlertCustom severity="error" message={inputErrorsMessage} />
                                        </Row>
                                </>
                                ): null}
                                {type === 'Feeding' ? <>
                                    <Row className="">
                                        <DatePicker
                                            textField={(props) => <TextField {...props}  />}
                                            label={t("Feeding Date")}
                                            onChange={(newValue) => setFeedingDate(newValue)}
                                            defaultValue={dayjs()}
                                        />
                                    </Row>
                                    <Row className="mt-4">
                                        <TextField
                                            fullWidth
                                            type="text"
                                            id="item-food"
                                            label={t("Food")}
                                            placeholder={t("What has been fed?")}
                                            InputLabelProps={{shrink: true}}
                                            inputRef={foodRef} />
                                    </Row>
                                    <Row className="mt-4">
                                        <Label>{t('Successful')}</Label>
                                        <Switch onChange={(evt) => setSuccess(evt.target.checked)}/>
                                    </Row>
                                    <div>
                                        <Row className="mt-4 row-cols-lg-auto  row-cols-auto align-items-center">
                                            <Col>
                                                <Tooltip title={t("Create")} disableFocusListener>
                                                    <button className="btn btn-primary" onClick={() => submitFeedingForm()}>
                                                        <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                                                    </button></Tooltip>
                                            </Col>
                                            <Col>
                                                <Tooltip title={t("Back")} disableFocusListener>
                                                    <button className="btn btn-secondary" onClick={() => navigate(`/items/${params.id}/show`)}>
                                                        <FontAwesomeIcon icon={faBackward}></FontAwesomeIcon>
                                                    </button>
                                                </Tooltip>
                                            </Col>
                                        </Row>
                                    </div>
                                </> : null}
                                {type === 'Observation' ? <>
                                <Row className="">
                                    <DatePicker
                                        textField={(props) => <TextField {...props}  />}
                                        label={t("Observation Date")}
                                        onChange={(newValue) => setObservationDate(newValue)}
                                        defaultValue={dayjs()}
                                    />
                                </Row>
                                <Row className="mt-4">
                                    <TextField
                                        fullWidth
                                        type="text"
                                        id="item-comment"
                                        label={t("Comment")}
                                        placeholder={t("")}
                                        InputLabelProps={{shrink: true}}
                                        inputRef={ObservationCommentRef} />
                                </Row>
                                <div>
                                    <Row className="mt-4 row-cols-lg-auto  row-cols-auto align-items-center">
                                        <Col>
                                            <Tooltip title={t("Create")} disableFocusListener>
                                                <button className="btn btn-primary" onClick={() => submitObservationForm()}>
                                                    <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                                                </button></Tooltip>
                                        </Col>
                                        <Col>
                                            <Tooltip title={t("Back")} disableFocusListener>
                                                <button className="btn btn-secondary" onClick={() => navigate(`/items/${params.id}/show`)}>
                                                    <FontAwesomeIcon icon={faBackward}></FontAwesomeIcon>
                                                </button>
                                            </Tooltip>
                                        </Col>
                                    </Row>
                                </div>
                            </> : null}
                                {type === 'Mating' ? <>
                                <Row className="">
                                    <DatePicker
                                        textField={(props) => <TextField {...props}  />}
                                        label={t("Mating Date")}
                                        onChange={(newValue) => setMatingDate(newValue)}
                                        defaultValue={dayjs()}
                                    />
                                </Row>
                                <Row className="mt-4">
                                    <TextField
                                        fullWidth
                                        type="text"
                                        id="item-comment"
                                        label={t("Comment")}
                                        placeholder={t("")}
                                        InputLabelProps={{shrink: true}}
                                        inputRef={MatingCommentRef} />
                                </Row>
                                <Row className="mt-4">
                                    <Label>{t('Successful')}</Label>
                                    <Switch onChange={(evt) => setSuccess(evt.target.checked)}/>
                                </Row>
                                <div>
                                    <Row className="mt-4 row-cols-lg-auto  row-cols-auto align-items-center">
                                        <Col>
                                            <Tooltip title={t("Create")} disableFocusListener>
                                                <button className="btn btn-primary" onClick={() => submitMatingForm()}>
                                                    <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                                                </button></Tooltip>
                                        </Col>
                                        <Col>
                                            <Tooltip title={t("Back")} disableFocusListener>
                                                <button className="btn btn-secondary" onClick={() => navigate(`/items/${params.id}/show`)}>
                                                    <FontAwesomeIcon icon={faBackward}></FontAwesomeIcon>
                                                </button>
                                            </Tooltip>
                                        </Col>
                                    </Row>
                                </div>
                            </> : null}
                                {type === 'Additional' ? <>
                                <Row className="">
                                    <DatePicker
                                        textField={(props) => <TextField {...props}  />}
                                        label={t("Additional Date")}
                                        onChange={(newValue) => setAdditionalDate(newValue)}
                                        defaultValue={dayjs()}
                                    />
                                </Row>
                                <Row className="mt-4">
                                    <TextField
                                        fullWidth
                                        type="text"
                                        id="item-comment"
                                        label={t("Comment")}
                                        placeholder={t("")}
                                        InputLabelProps={{shrink: true}}
                                        inputRef={AdditionalCommentRef} />
                                </Row>
                                <div>
                                    <Row className="mt-4 row-cols-lg-auto  row-cols-auto align-items-center">
                                        <Col>
                                            <Tooltip title={t("Create")} disableFocusListener>
                                                <button className="btn btn-primary" onClick={() => submitAdditionalForm()}>
                                                    <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                                                </button></Tooltip>
                                        </Col>
                                        <Col>
                                            <Tooltip title={t("Back")} disableFocusListener>
                                                <button className="btn btn-secondary" onClick={() => navigate(`/items/${params.id}/show`)}>
                                                    <FontAwesomeIcon icon={faBackward}></FontAwesomeIcon>
                                                </button>
                                            </Tooltip>
                                        </Col>
                                    </Row>
                                </div>
                            </> : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <Fragment>
            {renderItems()}
        </Fragment>
    );


}

export default ProtocolNew;