import React, {Fragment, useEffect, useState} from "react";
import {Row} from "reactstrap";
import {useTranslation} from "react-i18next";

import {useAuth0} from "@auth0/auth0-react";
import {getItemsByUserFromService} from "../services/ItemService";
import {toast} from "react-toastify";
import {
    Checkbox,
    FormControl, FormControlLabel,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Skeleton
} from "@mui/material";
import PDF from "../components/Pdf/Pdf";
import {PDFViewer} from "@react-pdf/renderer";
import {SelectChangeEvent} from "@mui/material";
import {getConfig} from "../config";
import {QRCodeSVG} from "qrcode.react";
import Switch from "@mui/material/Switch";

const Label = () => {
    const [loading, setLoading] = useState(true);
    const {t} = useTranslation();
    const {user, getAccessTokenSilently} = useAuth0();
    const [options, setOptions] = useState(null);
    const [values, setValues] = useState([]);
    const [select, setSelect] = useState([]);
    const [commonNameCheck, setCommonNameCheck] = useState(false);
    const [ageCheck, setAgeCheck] = useState(false);
    const [genderCheck, setGenderCheck] = useState(false);

    const handleChange = (event: SelectChangeEvent<typeof select>) => {
        const {
            target: { value },
        } = event;

        setSelect(
            typeof value === 'string' ? value.split(',') : value,
        );

        if (!value.length) {
            setSelect([])
        }


    };

    function compareByName(a, b) {
        return a.label.localeCompare(b.label);
    }

    function handleCommonNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setCommonNameCheck(event.target.checked)
    }

    function handleAgeChange(event: React.ChangeEvent<HTMLInputElement>) {
        setAgeCheck(event.target.checked)
    }

    function handleGenderChange(event: React.ChangeEvent<HTMLInputElement>) {
        setGenderCheck(event.target.checked)
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const accessToken = await getAccessTokenSilently();
            let itemListFromResponse = await getItemsByUserFromService(user.sub.split("|")[1], accessToken);
            if (itemListFromResponse?.items) {
                const valuesArray = {};
                const options = [];
                if (typeof itemListFromResponse?.items !== 'undefined') {
                    // eslint-disable-next-line array-callback-return
                    itemListFromResponse?.items.map((i) => {
                        valuesArray[i.id] = Object.values(i)
                        options[i.id] = {id : i.id, label : i.name, commonName : i.common_name, value : i.id, genders: i.amount_males + "." + i.amount_females + "." + i.amount_unknown, age : (i.age_year !== 1970 ? i.age_month + "/" + i.age_year : "") }
                    });
                }
                let sortedOptions = options.sort(compareByName);
                setValues(valuesArray);
                setOptions(sortedOptions)
                setLoading(false);
            }
            if (itemListFromResponse?.status === 400) {
                toast.error(t("Something went wrong, please try again later"));
            }
        }

        fetchData().catch(r => {
            console.log(r.message)
            setLoading(false)
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Fragment>
            <>
                <div className="row">
                    <h1 className="mb-3 mb-lg-2">{t("Label Generator")}</h1>
                </div>
                <div className="row">
                    <div className="mb-3 mb-lg-2 col-md-3">
                        <div className="card h-100">
                            <div className="card-body">
                                <>
                                    <div>
                                        <div>
                                            <Row>
                                                <span className="alert alert-info">
                                                    {t("Label Explanation")}
                                                 </span>
                                            </Row>
                                            <Row>
                                                {loading ?
                                                    <>
                                                        <Skeleton animation="wave"/>
                                                    </>
                                                    : null}
                                                {!loading && (options != null) && values ?
                                                    <>
                                                        <FormControl>
                                                            <InputLabel id="demo-multiple-checkbox-label">{t('Species')}</InputLabel>
                                                            <Select
                                                                labelId="label"
                                                                id="label-gen"
                                                                value={select}
                                                                label={t("Species")}
                                                                multiple
                                                                onChange={handleChange}
                                                                input={<OutlinedInput label={t('Species')} />}
                                                                renderValue={value => {
                                                                    let output = [];
                                                                    value.forEach(function (item) {
                                                                        output.push(options[item].label)
                                                                    });
                                                                    return output.join(', ')
                                                                }}>
                                                                {Object.keys(options).map((value) => {
                                                                    return <MenuItem key={value} value={value}>
                                                                                <Checkbox checked={select.indexOf(value) > -1} />
                                                                                <ListItemText primary={options[value].label + " (" + options[value].genders + ") " + options[value].age} />
                                                                        </MenuItem>
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                        <FormControl className="mt-1">
                                                            <FormControlLabel control={<Switch onChange={handleCommonNameChange} />} label={t("Hide Common Name")} />
                                                        </FormControl>
                                                        <FormControl className="mt-1">
                                                            <FormControlLabel control={<Switch onChange={handleGenderChange} />} label={t("Hide Gender")} />
                                                        </FormControl>
                                                        <FormControl className="mt-1">
                                                            <FormControlLabel control={<Switch onChange={handleAgeChange} />} label={t("Hide Age")} />
                                                        </FormControl>
                                                    </>
                                                    : null}
                                            </Row>
                                        </div>
                                    </div>
                                </>
                            </div>
                        </div>
                    </div>
                    <div className="mb-3 mb-lg-2 col-md-9">
                        <div className="card h-100">
                            <div className="card-body">
                                <Row>
                                    <h2 className="p-lg-0 mb-3 mb-lg-2">{t("Preview PDF")}</h2>
                                </Row>
                                <Row>
                                    {select.length > 0 ?
                                        Object.keys(options).map((value) => {
                                            return (<QRCodeSVG lsize={10} className="d-none" style={{height: "75%", maxWidth: "100%", width: "100%"}} value={getConfig().baseUrl + "/items/" + options[value].id + "/show"} id={options[value].id} key={options[value].id}></QRCodeSVG>)
                                        })
                                        : null
                                    }
                                    {select.length > 0 ?
                                        <PDFViewer id="pdf" height="800px" showToolbar={true} >
                                            <PDF species={select} options={options} commonNameCheck={commonNameCheck} genderCheck={genderCheck} ageCheck={ageCheck} />
                                        </PDFViewer>
                                        : null}
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </Fragment>
    );


}

export default Label;