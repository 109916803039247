import React, {Fragment, useRef, useState} from "react";
import {Row} from "reactstrap";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBackward, faFileImport, faUpload} from "@fortawesome/free-solid-svg-icons";
import AlertCustom from "../components/AlertCustom";
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Papa from "papaparse";
import {useAuth0} from "@auth0/auth0-react";
import {deleteAllByUserId, saveItemToDb} from "../services/ItemService";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

const ItemsImport = () => {
    const [inputErrors, setInputErrors] = useState(false);
    const [inputErrorsMessage, setInputErrorsMessage] = useState();
    const [parsedCSVData, setParsedCSVData] = useState(null);
    const uploadRef = useRef();
    const {t} = useTranslation();
    const [tableRows, setTableRows] = useState([]);
    const [values, setValues] = useState([]);
    const {user, getAccessTokenSilently} = useAuth0();
    const [importRunning, setImportRunning] = useState(null);
    const navigate = useNavigate();

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        accept: ".csv",
        width: 1,
    });

    const changeHandler = (event) => {
        setInputErrors(false);
        setInputErrorsMessage(null);
        if (typeof event.target.files[0].type !== 'undefined' && event.target.files[0].type !== 'text/csv') {
            setInputErrors(true);
            setInputErrorsMessage(t("NotACSVFile"));
            return;
        }
        Papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                const rowsArray = [];
                const valuesArray = [];

                if (typeof results.data !== 'undefined') {
                    // eslint-disable-next-line array-callback-return
                    results.data.map((i) => {
                        rowsArray.push(Object.keys(i));
                        valuesArray.push(Object.values(i));
                    });
                }
                setParsedCSVData(results.data);
                setTableRows(rowsArray[0]);
                setValues(valuesArray);

                let validationErrors = [];
                if (rowsArray[0].indexOf("Id") === -1) {
                   validationErrors.push(t("Id column missing"))
                }

                if (rowsArray[0].indexOf("Name") === -1) {
                    validationErrors.push(t("Name column missing"))
                }

                if (rowsArray[0].indexOf("Common Name") === -1) {
                    validationErrors.push(t("Common Name column missing"))
                }

                if (rowsArray[0].indexOf("Males") === -1) {
                    validationErrors.push(t("Males column missing"))
                }

                if (rowsArray[0].indexOf("Females") === -1) {
                    validationErrors.push(t("Females column missing"))
                }

                if (rowsArray[0].indexOf("Unsexed") === -1) {
                    validationErrors.push(t("Unsexed column missing"))
                }

                if (rowsArray[0].indexOf("Month") === -1) {
                    validationErrors.push(t("Month column missing"))
                }
                if (rowsArray[0].indexOf("Year") === -1) {
                    validationErrors.push(t("Year column missing"))
                }

                if (validationErrors.length >= 1) {
                    setInputErrors(true);
                    let errorMessage = "";
                    validationErrors.forEach(function (value) {
                        errorMessage += value + "\n";
                    })
                    setImportRunning(false);
                    setParsedCSVData(null);
                    setValues(null);
                    setTableRows(null);
                    setInputErrorsMessage(errorMessage);
                    return false;
                }
            },
        });
    };

    const importDataToDb = async () => {
        let userId = user.sub.split("|")[1];
        const accessToken = await getAccessTokenSilently();
        setImportRunning(true);
        let deleteAllUser = await deleteAllByUserId(userId, accessToken);
        if (deleteAllUser.status === 200) {
            // eslint-disable-next-line array-callback-return
            values.map(async function (el, index) {
                let data = {
                    "userId" : user.sub.split("|")[1],
                    "name" : el[1],
                    "commonName" : el[2],
                    "amountMales" : parseInt(el[3]),
                    "amountFemales" : parseInt(el[4]),
                    "amountUnknown" : parseInt(el[5]),
                    "ageMonth" : el[6] === "?" ? "1"  : el[6],
                    "ageYear" : el[7] === "?" ? "1970"  : el[7]
                }

                await saveItemToDb(data, accessToken);
            });
        }
        if (deleteAllUser.status !== 200) {
            toast.error(t("Something went wrong, please try again later"));
        }
        setImportRunning(false);
    }

    const renderItems = () => {
        return (
            <>
                <div className="row">
                    <h1 className="mb-3 mb-lg-2">{t("ImportItemsFromCSV")}</h1>
                </div>
                <div className="row">
                    {inputErrors ?
                        (<>
                                <Row className="mb-4">
                                    <AlertCustom severity="error" message={inputErrorsMessage} />
                                </Row>
                            </>
                        ): null}
                    <div className="mb-3 mb-lg-2 col-md-3">
                        <div className="card h-100">
                            <div className="card-body">
                                <>
                                    <div>
                                        <div>
                                            <Row>
                                                 <span className="alert alert-info">
                                                    {t("Import Explanation")}
                                                 </span>
                                                {importRunning ?
                                                <>
                                                    {t("Importing")}
                                                </>
                                                : null}
                                                {!parsedCSVData ?
                                                    <Button component="label" variant="contained" sx={{ borderRadius: 9999 }} startIcon={<FontAwesomeIcon icon={faUpload}></FontAwesomeIcon>} ref={uploadRef}>
                                                        {t('UploadACSVFile')}
                                                        <VisuallyHiddenInput type="file" accept={".csv"} onChange={changeHandler} />
                                                    </Button>
                                                    : null}
                                                {parsedCSVData ?
                                                    <Button component="label" variant="contained" color="success" className="btn-success mt-1" sx={{ borderRadius: 9999 }} disabled={importRunning} href="#" onClick={() => importDataToDb()}>
                                                        <FontAwesomeIcon icon={faFileImport}></FontAwesomeIcon>&nbsp;
                                                        {t('Start Import')}
                                                    </Button>
                                                    : null}
                                                <Button component="label" variant="contained" color="error" className="mt-1" disabled={importRunning} sx={{ borderRadius: 9999 }} href="/items" onClick={() => navigate("/items")}>
                                                    <FontAwesomeIcon icon={faBackward}></FontAwesomeIcon>&nbsp;
                                                    {t('Back')}
                                                </Button>
                                            </Row>
                                        </div>
                                    </div>
                                </>
                            </div>
                        </div>
                    </div>
                    <div className="mb-3 mb-lg-2 col-md-9">
                        <div className="card h-100">
                            <div className="card-body">
                                <Row>
                                    <h2 className="p-lg-0 mb-3 mb-lg-2">{t("Preview Data CSV")}</h2>
                                    {parsedCSVData ?
                                        <div className="table-responsive">
                                            <table className="table table-bordered">
                                                <thead>
                                                <tr>
                                                    {tableRows && tableRows.map((rows, index) => {
                                                        return <th key={index}>{rows}</th>;
                                                    })}
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {values && values.map((value, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            {value.map((val, i) => {
                                                                return <td key={i}>{val}</td>;
                                                            })}
                                                        </tr>
                                                    );
                                                })}
                                                </tbody>
                                            </table>
                                        </div>
                                    : t("Please upload CSV") }
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <Fragment>
            {renderItems()}
        </Fragment>
    );


}

export default ItemsImport;