import React from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useAuth0} from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

const AdminHome = ({handleMessage}) => {
    const {t} = useTranslation();
    const {user} = useAuth0();
    const navigate = useNavigate();
    const roles = user['https://myroles.com/roles'];

    if (!roles.find((element) => element === "Admin")) {
        navigate("/");
    }

    return (
        <div className="row">
            <h1 className="mb-3 mb-lg-5">{t("Admin")}</h1>
            <div className="col-sm-6 col-lg-4 mt-3">
                <div className="card">
                    <div className="card-body">
                        <h2>{t('View Users')}</h2>
                        <p>{t('View all Users')}</p>
                        <Link to="/admin/users" className="btn btn-primary">{t('GoTo') + " " + t('View Users')}</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default AdminHome;
