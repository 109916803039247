import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./utils/history";
import { getConfig } from "./config";
import "./i18n";
import { createTheme } from '@mui/material/styles';
import {ThemeProvider} from "@mui/material";
import AuthWrapper from "./utils/AuthWrapper";
import {isSafari, isFirefox } from 'react-device-detect'

const onRedirectCallback = appState => {
  history.replace({
    pathname: appState?.returnTo || window.location.pathname,
    search: '',
  });
};

const config = getConfig();

const isBrave = async()=> {
  // eslint-disable-next-line no-mixed-operators
  return (navigator.brave && await navigator.brave.isBrave() || false)
}

let useRefreshTokens = !!(isSafari || isFirefox || isBrave)

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  secret: config.secret,
  onRedirectCallback,
  authorizationParams: {
    ...(config.audience ? { audience: config.audience } : null),
    redirect_uri : window.location.origin
  },
  useRefreshTokens : useRefreshTokens,
  cacheLocation : useRefreshTokens ? 'localstorage' : 'memory'
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#343d46',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  }
});

const root = createRoot(document.getElementById('root'));
root.render(
    <Auth0Provider
        {...providerConfig}
    >
      <ThemeProvider theme={theme}>
        <AuthWrapper>
          <App />
        </AuthWrapper>
      </ThemeProvider>
    </Auth0Provider>
);
serviceWorker.unregister();
