import {useAuth0} from "@auth0/auth0-react";
import logo from "../../src/assets/img/spiderscanner-logo-big.svg";
import React from "react";
import {useTranslation} from "react-i18next";
const Login = ({type}) => {
    const {t} = useTranslation();
    const {loginWithRedirect} = useAuth0();

    const messages = {
        nope: t("NoPermission"),
        login: t("LoginToYourAccount"),
    }

    return (
        <>
            <div className="row text-center height-100vh justify-content-center align-items-center display-flex scanner-cm-main-rowLogin">
                <div className="col-sm-10 col-md-8 col-lg-6 text-center ">
                    <div className="card border-top-big">
                        <div className="card-body my-5">
                            <img src={logo} alt="" title="" width="350" className="img-fluid d-block mx-auto" />
                            <div className="mb-3">
                                <h3>{messages[type]}</h3>
                                <p>{ t("ForYourLogin") }</p>
                            </div>
                            <div className="">
                                {type === "login" ? <>
                                <button
                                    type="button"
                                    className="btn btn-primary mt-1"
                                    onClick={() => loginWithRedirect({ appState: { targetUrl: window.location.pathname } })}
                                        >
                                    { t("SignIn") }
                                </button> </>: null}
                                {type === "login" ? <button
                                    type="button"
                                    className="btn btn-secondary mt-1"
                                    onClick={() => loginWithRedirect({authorizationParams: {
                                            screen_hint: "signup",
                                        }})}
                                >
                                    { t("Register") }
                                </button> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login;