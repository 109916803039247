import React, {Fragment, useEffect, useRef, useState} from "react";
import {
    Skeleton, Tooltip
} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faTrash,
    faPen,
    faPlus,
    faFileExport,
    faMagnifyingGlass, faFileImport
} from "@fortawesome/free-solid-svg-icons";
import {useAuth0} from "@auth0/auth0-react";
import {deleteItemById, getItemsByUserFromService} from "../services/ItemService";
import {getConfig} from "../config";
import {useTranslation} from "react-i18next";
import DialogCustom from "../components/DialogCustom";
import {CSVLink} from "react-csv";
import {CardLink} from "reactstrap";
import CustomDataGrid from '../utils/DataGrid';
import {minTwoDigits} from "../utils/Helper";
import {toast} from "react-toastify";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import SpeedDial from "@mui/material/SpeedDial";
import {QRCodeSVG} from "qrcode.react";
import {useNavigate} from "react-router-dom";

const Items = () => {
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const {user, getAccessTokenSilently} = useAuth0();
    const {t} = useTranslation();
    const [itemToDelete, setItemToDelete] = useState(0);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const downloadCSVRef = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const accessToken = await getAccessTokenSilently();
            let itemListFromResponse = await getItemsByUserFromService(user.sub.split("|")[1], accessToken);
            if (itemListFromResponse?.items) {
                setItems(itemListFromResponse?.items);
                setLoading(false);
            }
            if (itemListFromResponse?.status === 400) {
                toast.error(t("Something went wrong, please try again later"));
            }
        }

        fetchData().catch(r => {
            console.log(r.message)
            setLoading(false)
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleDeleteDialog = (id) => {
        setOpenDeleteDialog(true);
        setItemToDelete(id);
    };

    const handleDeleteClose = () => {
        setOpenDeleteDialog(false);
    };

    const deleteItem = async () => {
        setLoading(true);
        setOpenDeleteDialog(false);
        const accessToken = await getAccessTokenSilently();
        let result = await deleteItemById(itemToDelete, accessToken);
        if (result?.status === 400) {
            toast.error(t("Something went wrong, please try again later"));
        }
        if (result.affectedRows > 0) {
            let accessToken = await getAccessTokenSilently();
            let itemListFromResponse = await getItemsByUserFromService(user.sub.split("|")[1], accessToken);
            if (itemListFromResponse?.items) {
                setItems(itemListFromResponse?.items);
            }
            if (itemListFromResponse?.status === 400) {
                toast.error(t("Something went wrong, please try again later"));
            }
        }
        setLoading(false);
    }

    function editItem(id) {
        navigate("/items/" + id + "/edit");
    }


    function showItem(id) {
        if (typeof id !== 'undefined') {
            navigate("/items/" + id + "/show");
        }
    }

    const headersCSV = [
        {label: "Id", key: "id"},
        {label: "Name", key: "name"},
        {label: "Common Name", key: "common_name"},
        {label: "Males", key: "amount_males"},
        {label: "Females", key: "amount_females"},
        {label: "Unsexed", key: "amount_unknown"},
        {label: "Month", key: "age_month"},
        {label: "Year", key: "age_year"},
    ];

    const rows: GridRowsProp = [];

    if(items && items?.length > 0) {
        items?.map((row, index) => (
            rows[index] = {
                "id" : index,
                "col1" : row.id,
                "col2" : row.name,
                "col3" : row.common_name,
                "col4" : row.amount_males,
                "col5" : row.amount_females,
                "col6" : row.amount_unknown,
                "col7" : row.age_year === 1970 ? "-" : minTwoDigits(row.age_month),
                "col8" : row.age_year === 1970 ? "-" : row.age_year,
                "col9" : row.id
            }
        ));
    }

    const columns: GridColDef[] = [
        {
            field: 'col1',
            headerName: t("QR-Code"),
            width: 100,
            editable: false,
            renderCell: (params: id) => (
                <QRCodeSVG value={getConfig().baseUrl + "/items/" + params.value + "/show"} />
            )
        },
        { field: 'col2', headerName: t("Name"), width: 200, editable: false },
        { field: 'col3', headerName: t("Common") + " " + t("Name"), width: 270, editable: false, sortable: false },
        { field: 'col4', headerName: t("Males"), width: 85, editable: false, sortable: false, align:'center' },
        { field: 'col5', headerName: t("Females"), width: 85, editable: false, sortable: false, align:'center' },
        { field: 'col6', headerName: t("Unknown"), width: 85, editable: false, sortable: false, align:'center' },
        { field: 'col7', headerName: t("Month"), width: 75, editable: false, sortable: false, align:'center'},
        { field: 'col8', headerName: t("Year"), width: 75, editable: false, sortable: false, align:'center'},
        {
            field: 'col9',
            headerName: t("Actions"),
            width: 250,
            editable: false,
            sortable: false,
            renderCell: (params: id) => (
                <>
                    <CardLink>
                        <Tooltip title={t("ShowItem")} disableFocusListener>
                            <button onClick={() => showItem(params.value)}
                                    className="btn btn-primary text-white">
                                <FontAwesomeIcon
                                    icon={faMagnifyingGlass}></FontAwesomeIcon>
                            </button>
                        </Tooltip>
                    </CardLink>
                    <CardLink>
                        <Tooltip title={t("ItemEdit")} disableFocusListener>
                            <button onClick={() => editItem(params.value)}
                                    className="btn btn-info text-white">
                                <FontAwesomeIcon
                                    icon={faPen}></FontAwesomeIcon></button>
                        </Tooltip>
                    </CardLink>
                    <CardLink>
                        <Tooltip title={t("Delete")} disableFocusListener>
                            <button
                                onClick={() => handleDeleteDialog(params.value)}
                                className="btn btn-secondary">
                                <FontAwesomeIcon
                                    icon={faTrash}></FontAwesomeIcon>
                            </button>
                        </Tooltip>
                    </CardLink>
                </>
            )
        }
    ];

    const handleSpeedClick  = (operation) => {
        switch (operation) {
            case "addNew":
                navigate("/items/new");
                break;
            case "exportList":
                downloadCSVRef.current.link.click();
                break;
            case "importList":
                navigate("/items/import");
                break;
            default:
                break;
        }
    };
    const actions = [
        { icon: <FontAwesomeIcon className="d-block" icon={faPlus}></FontAwesomeIcon>, name: t("AddNewItem"), operation : "addNew" },
        { icon: <FontAwesomeIcon className="d-block" icon={faFileExport}></FontAwesomeIcon>, name: t("ImportItemsFromCSV"), operation : "importList" },
        { icon: <FontAwesomeIcon className="d-block" icon={faFileImport}></FontAwesomeIcon>, name: t("ExportItemsToCSV"), operation : "exportList" }
    ];

    function renderItems() {
        return (
            <div className="row">
                <h1 className="mb-3 mb-lg-5">{t("StockList")}</h1>
                <div className="mb-3 mb-lg-5">
                    <div className="card h-100">
                        <div className="card-body">
                            {loading ?
                                <>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                            <Skeleton animation="wave"/>
                                        </div>
                                    </div>
                                </>
                                : null}
                            {!loading ?
                                <>
                                    <div className="row">
                                        <div className="col-md-12">
                                            {rows.length === 0 ?
                                                <div style={{ height: 300, width: '100%' }}>
                                                    <CustomDataGrid rows={rows} columns={columns} />
                                                </div>
                                                : <CustomDataGrid rows={rows} columns={columns} />
                                            }
                                        </div>
                                    </div>
                                    <CSVLink className="btn btn-primary mb-5 float-end text-white text-decoration-none d-none" ref={downloadCSVRef} data={items} headers={headersCSV} filename={"stock_list.csv"}>
                                        <Tooltip title={t("ExportItemsToCSV")} disableFocusListener>
                                            <FontAwesomeIcon className="d-block" icon={faFileExport} alt={t("ExportItemsToCSV")}></FontAwesomeIcon>
                                        </Tooltip>
                                    </CSVLink>
                                </>
                                : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <Fragment>
            {renderItems()}
            <DialogCustom
                handleClose={handleDeleteClose}
                handleAction={deleteItem}
                message={t("ToDeleteTheSelectedItem")}
                title={t("AreYouSure?")}
                actionButton={t("Delete")}
                cancelButton={t("Cancel")}
                openDialog={openDeleteDialog}
            />
            <SpeedDial
                ariaLabel="SpeedDial"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
                icon={<SpeedDialIcon />}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        FabProps={{color: 'primary'}}
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={(e) => {
                            handleSpeedClick(action.operation)
                        }}
                    />
                ))}
            </SpeedDial>
        </Fragment>
    );
}

export default Items;