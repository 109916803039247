import {getConfig} from "../config";

async function saveItemToDbByType(type, data, token) {
    let scriptId = null;
    switch (type) {
        case "feeding_protocol":
            scriptId = 1;
            break;
        case "observation_protocol":
            scriptId = 2;
            break;
        case "mating_protocol":
            scriptId = 3;
            break;
        case "additional":
            scriptId = 4;
            break;
        default:
            break;
    }
    const res = await fetch(getConfig().url + `/api/items/protocol/${scriptId}` , {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type"  : "application/json",
            Authorization    : "BEARER " + token,
        },
        body : JSON.stringify(data)
    });

    return res.json();
}

export {saveItemToDbByType}