import React, {Fragment, useEffect, useRef, useState} from "react";
import {
    Box,
    Skeleton,
    Tab, TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Tabs,
    tabsClasses,
    Tooltip
} from "@mui/material";

import {t} from "i18next";
import {getItemByIdFromService} from "../services/ItemService";
import {useAuth0} from "@auth0/auth0-react";
import {useNavigate, useParams} from "react-router-dom";
import {getConfig} from "../config";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, CardLink, Table} from "reactstrap";
import {faDownload} from "@fortawesome/free-solid-svg-icons/faDownload";
import {
    faPencil,
    faTrash,
    faUtensils,
    faGlasses,
    faHeart,
    faStar
} from "@fortawesome/free-solid-svg-icons";
import {deleteFeedingById, getFeedingProtocolByItemId} from "../services/FeedingService";
import DialogCustom from "../components/DialogCustom";
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import {deleteObservationById, getObservationProtocolByItemId} from "../services/ObservationService";
import {deleteMatingById, getMatingProtocolByItemId} from "../services/MatingService";
import {getAdditionalProtocolByItemId} from "../services/AdditionalService";
import {minTwoDigits} from "../utils/Helper";
import {GridRowsProp} from "@mui/x-data-grid";
import {GridColDef} from "@mui/x-data-grid";
import dayjs from "dayjs";
import {toast} from "react-toastify";
import CustomProtocolDataGrid from "../utils/DataGridProtocols";
import {QRCodeSVG} from "qrcode.react";

const ItemShow = () => {
    const {user, getAccessTokenSilently} = useAuth0();
    const [loadingDetails, setLoadingDetails] = useState(true);
    const [loadingTabs, setLoadingTabs] = useState(true);
    const [loadingFeedingProtocol, setLoadingFeedingProtocol] = useState(true);
    const [loadingObservationProtocol, setLoadingObservationProtocol] = useState(false);
    const [loadingMatingProtocol, setLoadingMatingProtocol] = useState(false);
    const [loadingAdditional, setLoadingAdditional] = useState(false);
    const [item, setItem] = useState();
    const [feedingToDelete, setFeedingToDelete] = useState();
    const [observationToDelete, setObservationToDelete] = useState();
    const [additionalToDelete, setAdditionalToDelete] = useState();
    const [matingToDelete, setMatingToDelete] = useState();
    const params = useParams();
    const qrRef = useRef();
    const [tabsValue, setTabsValue] = React.useState(0);
    const [feedingProtocol, setFeedingProtocol] = React.useState([]);
    const [observationProtocol, setObservationProtocol] = React.useState([]);
    const [matingProtocol, setMatingProtocol] = React.useState([]);
    const [additionalProtocol, setAdditionalProtocol] = React.useState([]);
    const [openDeleteDialogFeeding, setOpenDeleteDialogFeeding] = useState(false);
    const [openDeleteDialogObservation, setOpenDeleteDialogObservation] = useState(false);
    const [openDeleteDialogMating, setOpenDeleteDialogMating] = useState(false);
    const [openDeleteDialogAdditional, setOpenDeleteDialogAdditional] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            setLoadingDetails(true);
            const accessToken = await getAccessTokenSilently();
            let itemFromResponse = await getItemByIdFromService(params.id, accessToken);
            if (itemFromResponse?.item) {
                setItem(itemFromResponse?.item);
            }

            if (itemFromResponse?.status === 400) {
                toast.error(t("Something went wrong, please try again later"));
            }

            if (tabsValue === 0) {
                const accessToken = await getAccessTokenSilently();
                let feedingProtocolFromResponse = await getFeedingProtocolByItemId(params.id, accessToken);
                if (feedingProtocolFromResponse?.feedingProtocol) {
                    setFeedingProtocol(feedingProtocolFromResponse?.feedingProtocol);
                    setLoadingFeedingProtocol(false);
                }
                if (feedingProtocolFromResponse?.status === 400) {
                    toast.error(t("Something went wrong, please try again later"));
                }
            }
            setLoadingDetails(false);
            setLoadingTabs(false)
        }

        fetchData().catch(r => {
            console.log(r.message)
            setLoadingDetails(false)
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSpeedClick  = (operation) => {
        switch (operation) {
            case "redirectFeeding":
                navigate(`/items/${params.id}/feeding-protocol`);
                break;
            case "redirectObservation":
                navigate(`/items/${params.id}/observation-protocol`);
                break;
            case "redirectMating":
                navigate(`/items/${params.id}/mating-protocol`);
                break;
            case "redirectAdditional":
                navigate(`/items/${params.id}/additional`);
                break;
            default:
                break;
        }
    };
    const actions = [
        { icon: <FontAwesomeIcon className="d-block" icon={faUtensils}></FontAwesomeIcon>, name: t("AddNewFeedingProtocol"), operation : "redirectFeeding" },
        { icon: <FontAwesomeIcon className="d-block" icon={faGlasses}></FontAwesomeIcon>, name: t("AddNewObservationProtocol"), operation : "redirectObservation" },
        { icon: <FontAwesomeIcon className="d-block" icon={faHeart}></FontAwesomeIcon>, name: t("AddNewMatingProtocol"), operation : "redirectMating" },
        { icon: <FontAwesomeIcon className="d-block" icon={faStar}></FontAwesomeIcon>, name: t("AddNewAdditional"), operation : "redirectAdditional" },
    ];


    const handleTabChange = async (event, newValue) => {
        setTabsValue(newValue);
        const accessToken = await getAccessTokenSilently();
        switch (newValue) {
            case 0 :
                setLoadingFeedingProtocol(true);
                let feedingProtocolFromResponse = await getFeedingProtocolByItemId(params.id, accessToken);
                if (feedingProtocolFromResponse?.feedingProtocol) {
                    setFeedingProtocol(feedingProtocolFromResponse?.feedingProtocol);
                    setLoadingFeedingProtocol(false);
                }
                if (feedingProtocolFromResponse?.status === 400) {
                    toast.error(t("Something went wrong, please try again later"));
                }
                break;
            case 1 :
                setLoadingObservationProtocol(true);
                let observationProtocolFromResponse = await getObservationProtocolByItemId(params.id, accessToken);
                if (observationProtocolFromResponse?.observationProtocol) {
                    setObservationProtocol(observationProtocolFromResponse?.observationProtocol);
                    setLoadingObservationProtocol(false);
                }
                if (observationProtocolFromResponse?.status === 400) {
                    toast.error(t("Something went wrong, please try again later"));
                }
                break;
            case 2 :
                setLoadingMatingProtocol(true);
                let matingProtocolFromResponse = await getMatingProtocolByItemId(params.id, accessToken);
                if (matingProtocolFromResponse?.matingProtocol) {
                    setMatingProtocol(matingProtocolFromResponse?.matingProtocol);
                    setLoadingMatingProtocol(false);
                }
                if (matingProtocolFromResponse?.status === 400) {
                    toast.error(t("Something went wrong, please try again later"));
                }
                break;
            case 3 :
                setLoadingAdditional(true);
                let additionalProtocolFromResponse = await getAdditionalProtocolByItemId(params.id, accessToken);
                if (additionalProtocolFromResponse?.additionalProtocol) {
                    setAdditionalProtocol(additionalProtocolFromResponse?.additionalProtocol);
                    setLoadingAdditional(false);
                }
                if (additionalProtocolFromResponse?.status === 400) {
                    toast.error(t("Something went wrong, please try again later"));
                }
                break;
            default:
                break;
        }
    };

    const CustomTabPanel = (props) => {
        const {children, value, index, ...other} = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{p: 3}}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const downloadBlob = (blob, filename) => {
        const objectUrl = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = objectUrl;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setTimeout(() => URL.revokeObjectURL(objectUrl), 5000);
    }

    const downloadQR = () => {
        const svg = qrRef.current.innerHTML;
        const blob = new Blob([svg], {type: "image/svg+xml"});
        downloadBlob(blob, `qr-code.svg`);
    };

    const handleDeleteDialogFeeding = (id) => {
        setOpenDeleteDialogFeeding(true);
        setFeedingToDelete(id);
    };

    const handleDeleteDialogObservation = (id) => {
        setOpenDeleteDialogObservation(true);
        setObservationToDelete(id);
    };

    const handleDeleteDialogMating = (id) => {
        setOpenDeleteDialogMating(true);
        setMatingToDelete(id);
    };

    const handleDeleteDialogAdditional = (id) => {
        setOpenDeleteDialogAdditional(true);
        setAdditionalToDelete(id);
    };

    const deleteFeeding = async () => {
        setLoadingFeedingProtocol(true);
        const accessToken = await getAccessTokenSilently();
        let result = await deleteFeedingById(params.id, feedingToDelete, accessToken);
        if (result?.status === 400) {
            toast.error(t("Something went wrong, please try again later"));
        }
        if (result.affectedRows > 0) {
            let feedingProtcolFromResponse = await getFeedingProtocolByItemId(params.id, accessToken);
            if (feedingProtcolFromResponse?.feedingProtocol) {
                setFeedingProtocol(feedingProtcolFromResponse?.feedingProtocol);
            }
            if (feedingProtcolFromResponse?.status === 400) {
                toast.error(t("Something went wrong, please try again later"));
            }
        }
        setOpenDeleteDialogFeeding(false);
        setLoadingFeedingProtocol(false);
    }

    const deleteObservation = async () => {
        setLoadingObservationProtocol(true);
        const accessToken = await getAccessTokenSilently();
        let result = await deleteObservationById(params.id, observationToDelete, accessToken);
        if (result?.status === 400) {
            toast.error(t("Something went wrong, please try again later"));
        }
        if (result.affectedRows > 0) {
            let observationProtocol = await getObservationProtocolByItemId(params.id, accessToken);
            if (observationProtocol?.observationProtocol) {
                setObservationProtocol(observationProtocol?.observationProtocol);
            }
            if (observationProtocol?.status === 400) {
                toast.error(t("Something went wrong, please try again later"));
            }
        }
        setOpenDeleteDialogObservation(false);
        setLoadingObservationProtocol(false);
    }

    const deleteMating = async () => {
        setLoadingMatingProtocol(true);
        const accessToken = await getAccessTokenSilently();
        let result = await deleteMatingById(params.id, matingToDelete, accessToken);
        if (result?.status === 400) {
            toast.error(t("Something went wrong, please try again later"));
        }
        if (result.affectedRows > 0) {
            let matingProtocol = await getMatingProtocolByItemId(params.id, accessToken);
            if (matingProtocol?.matingProtocol) {
                setMatingProtocol(matingProtocol?.matingProtocol);
            }
            if (matingProtocol?.status === 400) {
                toast.error(t("Something went wrong, please try again later"));
            }
        }

        setOpenDeleteDialogMating(false);
        setLoadingMatingProtocol(false);
    }

    const deleteAdditional = async () => {
        setLoadingAdditional(true);
        const accessToken = await getAccessTokenSilently();
        let result = await deleteAdditional(params.id, additionalToDelete, accessToken);
        if (result?.status === 400) {
            toast.error(t("Something went wrong, please try again later"));
        }
        if (result.affectedRows > 0) {
            let addtionalProtocol = await getAdditionalProtocolByItemId(params.id, accessToken);
            if (addtionalProtocol?.additionalProtocol) {
                setAdditionalProtocol(addtionalProtocol?.additionalProtocol);
            }
            if (addtionalProtocol?.status === 400) {
                toast.error(t("Something went wrong, please try again later"));
            }
        }
        setOpenDeleteDialogAdditional(false);
        setLoadingAdditional(false);
    }

    const handleDeleteFeedingClose = () => {
        setOpenDeleteDialogFeeding(false);
    };

    const handleDeleteAdditionalClose = () => {
        setOpenDeleteDialogAdditional(false);
    };

    const handleDeleteObservationClose = () => {
        setOpenDeleteDialogObservation(false);
    };

    const handleDeleteMatingClose = () => {
        setOpenDeleteDialogMating(false);
    };

    const rowsFeeding: GridRowsProp = [];
    if(feedingProtocol && feedingProtocol?.length > 0) {
        feedingProtocol?.map((row, index) => (
            rowsFeeding[index] = {
                "id" : index,
                "col1" : dayjs(row.entry_date).format('DD/MM/YYYY'),
                "col3" : row.comment,
                "col4" : row.success ? t("Yes") : t("No"),
                "col5" : row.id,
            }
        ));
    }
    const columnsFeeding: GridColDef[] = [
        { field: 'col1', headerName: t("Feeding Date"), width: 300, editable: false, sortable: false, align:'left' },
        { field: 'col3', headerName: t("Food"), width: 600, editable: false, sortable: false, align:'left'},
        { field: 'col4', headerName: t("Successful"), width: 180, editable: false, sortable: false, align:'left' },
        {
            field: 'col5',
            headerName: t("Action"),
            width: 90,
            editable: false,
            align:'right',
            sortable: false,
            renderCell: (params: id) => (
                <>
                    <CardLink>
                        <Tooltip title={t("Delete")} disableFocusListener>
                            <button
                                className="btn btn-sm btn-secondary"
                                onClick={() => handleDeleteDialogFeeding(params.value)}
                            >
                                <FontAwesomeIcon
                                    icon={faTrash}></FontAwesomeIcon>
                            </button>
                        </Tooltip>
                    </CardLink>
                </>
            )
        }
    ];

    const rowsObservation: GridRowsProp = [];
    if(observationProtocol && observationProtocol?.length > 0) {
        observationProtocol?.map((row, index) => (
            rowsObservation[index] = {
                "id" : index,
                "col1" : dayjs(row.entry_date).format('DD/MM/YYYY'),
                "col2" : row.comment,
                "col3" : row.id,
            }
        ));
    }
    const columnsObservation: GridColDef[] = [
        { field: 'col1', headerName: t("Observation Date"), width: 300, editable: false, sortable: false, align:'left' },
        { field: 'col2', headerName: t("Comment"), width: 780, editable: false, sortable: false, align:'left'},
        {
            field: 'col3',
            headerName: t("Action"),
            width: 90,
            editable: false,
            align:'right',
            sortable: false,
            renderCell: (params: id) => (
                <>
                    <CardLink>
                        <Tooltip title={t("Delete")} disableFocusListener>
                            <button
                                className="btn btn-sm btn-secondary"
                                onClick={() => handleDeleteDialogObservation(params.value)}
                            >
                                <FontAwesomeIcon
                                    icon={faTrash}></FontAwesomeIcon>
                            </button>
                        </Tooltip>
                    </CardLink>
                </>
            )
        }
    ];

    const rowsMating: GridRowsProp = [];
    if(matingProtocol && matingProtocol?.length > 0) {
        matingProtocol?.map((row, index) => (
            rowsMating[index] = {
                "id" : index,
                "col1" : dayjs(row.entry_date).format('DD/MM/YYYY'),
                "col2" : row.comment,
                "col3" : row.success ? t("Yes") : t("No"),
                "col4" : row.id,
            }
        ));
    }
    const columnsMating: GridColDef[] = [
        { field: 'col1', headerName: t("Mating Date"), width: 300, editable: false, sortable: false, align:'left' },
        { field: 'col2', headerName: t("Comment"), width: 600, editable: false, sortable: false, align:'left'},
        { field: 'col3', headerName: t("Successful"), width: 180, editable: false, sortable: false, align:'left' },
        {
            field: 'col4',
            headerName: t("Action"),
            width: 90,
            editable: false,
            align:'right',
            sortable: false,
            renderCell: (params: id) => (
                <>
                    <CardLink>
                        <Tooltip title={t("Delete")} disableFocusListener>
                            <button
                                className="btn btn-sm btn-secondary"
                                onClick={() => handleDeleteDialogMating(params.value)}
                            >
                                <FontAwesomeIcon
                                    icon={faTrash}></FontAwesomeIcon>
                            </button>
                        </Tooltip>
                    </CardLink>
                </>
            )
        }
    ];

    const rowsAdditional: GridRowsProp = [];
    if(additionalProtocol && additionalProtocol?.length > 0) {
        additionalProtocol?.map((row, index) => (
            rowsAdditional[index] = {
                "id" : index,
                "col1" : dayjs(row.entry_date).format('DD/MM/YYYY'),
                "col2" : row.comment,
                "col3" : row.id,
            }
        ));
    }
    const columnsAdditional: GridColDef[] = [
        { field: 'col1', headerName: t("Additional Date"), width: 300, editable: false, sortable: false, align:'left' },
        { field: 'col2', headerName: t("Comment"), width: 780, editable: false, sortable: false, align:'left'},
        {
            field: 'col3',
            headerName: t("Action"),
            width: 90,
            editable: false,
            align:'right',
            sortable: false,
            renderCell: (params: id) => (
                <>
                    <CardLink>
                        <Tooltip title={t("Delete")} disableFocusListener>
                            <button
                                className="btn btn-sm btn-secondary"
                                onClick={() => handleDeleteDialogAdditional(params.value)}
                            >
                                <FontAwesomeIcon
                                    icon={faTrash}></FontAwesomeIcon>
                            </button>
                        </Tooltip>
                    </CardLink>
                </>
            )
        }
    ];

    const renderItem = () => {
        return (
            <>
                <div className="row" id={user.sub}>
                    <div className="col-md-12">
                        <h1 className="d-inline-block mb-3 mb-lg-5">{t("ItemInformation")}</h1>
                    </div>
                    <div className="col-lg-6 col-xxl-6">
                        <div className="card h-100">
                            <div className="card-body">
                                {loadingDetails ?
                                    <>
                                        <Skeleton
                                            variant="rectangular"
                                            width={300}
                                            height={300}
                                            animation="wave"
                                        />
                                    </>
                                    : null}
                                {!loadingDetails ?
                                    <>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h2 className="float-start">{t("QRCode")}</h2>
                                                <div className="w-auto float-end">
                                                    <Button className="btn-primary mb-2 mb-md-3 mb-lg-5"
                                                            onClick={() => downloadQR(getConfig().url + "/items/" + item?.id)}>
                                                        <Tooltip title={t("DownloadQR")} disableFocusListener>
                                                            <FontAwesomeIcon className="d-block"
                                                                             icon={faDownload}></FontAwesomeIcon>
                                                        </Tooltip>
                                                    </Button>
                                                </div>
                                                <div className="w-100 float-none text-center">
                                                    <div className="QR-Code" ref={qrRef}>
                                                        <QRCodeSVG className="mt-2 mb-3" value={getConfig().baseUrl + "/items/" + item?.id + "/show"} style={{
                                                            height: "200px",
                                                            maxWidth: "100%",
                                                            width: "100%"
                                                        }} />
                                                        <small>{getConfig().baseUrl + "/items/" + item?.id + "/show"}</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    : null}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xxl-6 mt-3 mt-md-3 mt-lg-0">
                        <div className="card h-100">
                            <div className="card-body">
                                {loadingDetails ?
                                    <>
                                        <Skeleton animation="wave"/>
                                        <Skeleton animation="wave"/>
                                        <Skeleton animation="wave"/>
                                        <Skeleton animation="wave"/>
                                        <Skeleton animation="wave"/>
                                        <Skeleton animation="wave"/>
                                        <Skeleton animation="wave"/>
                                        <Skeleton animation="wave"/>
                                    </>
                                    : null}
                                {!loadingDetails ?
                                    <>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h2 className="float-start">{t("Info")}</h2>
                                                <div className="w-auto float-end">
                                                    <Button className="btn-primary mb-2 mb-md-3 mb-lg-5"
                                                            onClick={() => navigate("/items/" + item?.id + "/edit?referer=show")}>
                                                        <Tooltip title={t("ItemEdit")} disableFocusListener>
                                                            <FontAwesomeIcon className="d-block"
                                                                             icon={faPencil}></FontAwesomeIcon>
                                                        </Tooltip>
                                                    </Button>
                                                </div>
                                                <div className="w-100 float-none text-center">
                                                    <TableContainer>
                                                        <Table aria-label="simple table">
                                                            <TableBody>
                                                                <TableRow key={1}
                                                                          sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                                    <TableCell align="left" className="regular">
                                                                        {t("Name")}
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        {item?.name}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow
                                                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                                    <TableCell align="left" className="regular">
                                                                        {t("Common")} {t("Name")}
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        {item?.common_name}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow
                                                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                                    <TableCell align="left" className="regular">
                                                                        {t("Amount")} {t("Males")}
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        {item?.amount_males}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow
                                                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                                    <TableCell align="left" className="regular">
                                                                        {t("Amount")} {t("Females")}
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        {item?.amount_females}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow
                                                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                                    <TableCell align="left" className="regular">
                                                                        {t("Amount")} {t("Unsexed")}
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        {item?.amount_unknown}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow
                                                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                                    <TableCell align="left" className="regular">
                                                                        {t("AgeMonth")}
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        {item?.age_year === 1970 && minTwoDigits(item?.age_month) === "01" ? "-" : minTwoDigits(item?.age_month)}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow
                                                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                                                    <TableCell align="left" className="regular">
                                                                        {t("AgeYear")}
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        {item?.age_year === 1970 && minTwoDigits(item?.age_month) === "01" ? "-" : item?.age_year}
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="mt-3 mt-lg-5 mb-3 mb-lg-5">
                        <div className="card h-100">
                            <div className="card-body">
                                {loadingTabs ?
                                    <>
                                        <Skeleton
                                            variant="rectangular"
                                            width={210}
                                            height={110}
                                            animation="wave"
                                        />
                                        <Skeleton animation="wave"/>
                                        <Skeleton animation="wave"/>
                                        <Skeleton animation="wave"/>
                                        <Skeleton animation="wave"/>
                                        <Skeleton animation="wave"/>
                                        <Skeleton animation="wave"/>
                                        <Skeleton animation="wave"/>
                                        <Skeleton animation="wave"/>
                                    </>
                                    : null}
                                {!loadingTabs ?
                                    <>
                                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                            <Tabs value={tabsValue} onChange={handleTabChange} variant="scrollable"
                                                  scrollButtons
                                                  allowScrollButtonsMobile sx={{
                                                [`& .${tabsClasses.scrollButtons}`]: {
                                                    '&.Mui-disabled': {opacity: 0.3},
                                                },
                                            }}>
                                                <Tab label={t("FeedingProtocol")} {...a11yProps(0)} />
                                                <Tab label={t("ObservationProtocol")} {...a11yProps(1)} />
                                                <Tab label={t("MatingProtocol")} {...a11yProps(2)} />
                                                <Tab label={t("Additional")} {...a11yProps(3)} />
                                            </Tabs>
                                        </Box>
                                        <CustomTabPanel value={tabsValue} index={0}>
                                            {!loadingFeedingProtocol ?
                                                <>
                                                    {rowsFeeding.length === 0 ?
                                                        <div style={{ height: 250, width: '100%' }}>
                                                            <CustomProtocolDataGrid rows={rowsFeeding} columns={columnsFeeding} />
                                                        </div>
                                                        : <CustomProtocolDataGrid rows={rowsFeeding} columns={columnsFeeding} />
                                                    }
                                                    <DialogCustom
                                                        handleClose={handleDeleteFeedingClose}
                                                        handleAction={deleteFeeding}
                                                        message={t("ToDeleteTheSelectedFeeding")}
                                                        title={t("AreYouSure?")}
                                                        actionButton={t("Delete")}
                                                        cancelButton={t("Cancel")}
                                                        openDialog={openDeleteDialogFeeding}
                                                    />
                                                </>
                                                :
                                                <>
                                                    <Skeleton animation="wave"/>
                                                    <Skeleton animation="wave"/>
                                                    <Skeleton animation="wave"/>
                                                    <Skeleton animation="wave"/>
                                                </>
                                            }
                                        </CustomTabPanel>
                                        <CustomTabPanel value={tabsValue} index={1}>
                                            {!loadingObservationProtocol ?
                                                <>
                                                    {rowsObservation.length === 0 ?
                                                        <div style={{ height: 250, width: '100%' }}>
                                                            <CustomProtocolDataGrid rows={rowsObservation} columns={columnsObservation} />
                                                        </div>
                                                        : <CustomProtocolDataGrid rows={rowsObservation} columns={columnsObservation} />
                                                    }
                                                    <DialogCustom
                                                        handleClose={handleDeleteObservationClose}
                                                        handleAction={deleteObservation}
                                                        message={t("ToDeleteTheSelectedObservation")}
                                                        title={t("AreYouSure?")}
                                                        actionButton={t("Delete")}
                                                        cancelButton={t("Cancel")}
                                                        openDialog={openDeleteDialogObservation}
                                                    />
                                                </>
                                                :
                                                <>
                                                    <Skeleton animation="wave"/>
                                                    <Skeleton animation="wave"/>
                                                    <Skeleton animation="wave"/>
                                                    <Skeleton animation="wave"/>
                                                </>
                                            }
                                        </CustomTabPanel>
                                        <CustomTabPanel value={tabsValue} index={2}>
                                            {!loadingMatingProtocol ?
                                                <>
                                                    {rowsMating.length === 0 ?
                                                        <div style={{ height: 250, width: '100%' }}>
                                                            <CustomProtocolDataGrid rows={rowsMating} columns={columnsMating} />
                                                        </div>
                                                        : <CustomProtocolDataGrid rows={rowsMating} columns={columnsMating} />
                                                    }
                                                    <DialogCustom
                                                        handleClose={handleDeleteMatingClose}
                                                        handleAction={deleteMating}
                                                        message={t("ToDeleteTheSelectedMating")}
                                                        title={t("AreYouSure?")}
                                                        actionButton={t("Delete")}
                                                        cancelButton={t("Cancel")}
                                                        openDialog={openDeleteDialogMating}
                                                    />
                                                </>
                                                :
                                                <>
                                                    <Skeleton animation="wave"/>
                                                    <Skeleton animation="wave"/>
                                                    <Skeleton animation="wave"/>
                                                    <Skeleton animation="wave"/>
                                                </>
                                            }
                                        </CustomTabPanel>
                                        <CustomTabPanel value={tabsValue} index={3}>
                                            {!loadingAdditional ?
                                                <>
                                                    {rowsAdditional.length === 0 ?
                                                        <div style={{ height: 250, width: '100%' }}>
                                                            <CustomProtocolDataGrid rows={rowsAdditional} columns={columnsAdditional} />
                                                        </div>
                                                        : <CustomProtocolDataGrid rows={rowsAdditional} columns={columnsAdditional} />
                                                    }
                                                    <DialogCustom
                                                        handleClose={handleDeleteAdditionalClose}
                                                        handleAction={deleteAdditional}
                                                        message={t("ToDeleteTheSelectedAdditional")}
                                                        title={t("AreYouSure?")}
                                                        actionButton={t("Delete")}
                                                        cancelButton={t("Cancel")}
                                                        openDialog={openDeleteDialogAdditional}
                                                    />
                                                </>
                                                :
                                                <>
                                                    <Skeleton animation="wave"/>
                                                    <Skeleton animation="wave"/>
                                                    <Skeleton animation="wave"/>
                                                    <Skeleton animation="wave"/>
                                                </>
                                            }
                                        </CustomTabPanel>
                                    </>
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <Fragment>
            {renderItem()}
            <SpeedDial
                ariaLabel="SpeedDial"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
                icon={<SpeedDialIcon />}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        FabProps={{color: 'primary'}}
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={(e) => {
                            handleSpeedClick(action.operation)
                        }}
                    />
                ))}
            </SpeedDial>
        </Fragment>
    );


}

export default ItemShow;