import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from "../components/Loading";

function Wrapper({ children }) {
    const { isLoading } = useAuth0();
    if (isLoading) {
        return <Loading/>;
    }
    return <>{children}</>;
}
export default Wrapper;
