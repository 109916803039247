import {getConfig} from "../config";

async function deleteUserByUserId(userId, token) {
    const res = await fetch(getConfig().url + `/api/user` , {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type"  : "application/json",
            Authorization    : "BEARER " + token,
        },
        body : JSON.stringify({ "userId" : userId })
    });

    return res.json();
}

async function getAuth0UserBy(token) {
    const res = await fetch(getConfig().url + `/api/user` , {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type"  : "application/json",
            Authorization    : "BEARER " + token,
        }
    });

    return res.json();
}


export {deleteUserByUserId, getAuth0UserBy}