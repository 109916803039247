import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";

const NotFound =() => {
    const {t} = useTranslation();

    return (
        <div>
            <h1>{t('NotFound')}</h1>
            <p>{t('HelpfulLinks')}</p>
            <ul>
                <li><Link to='/'>{t('Dashboard')}</Link></li>
                <li><Link to='/items'>{t('StockList')}</Link></li>
            </ul>
        </div>
    )
}

export default NotFound;
