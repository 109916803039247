import {getConfig} from "../config";

async function getFeedingProtocolByItemId(itemId, token) {
    const res = await fetch(getConfig().url + `/api/item/` + itemId + `/feeding-protocol`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type"  : "application/json",
            Authorization    : "BEARER " + token,
        },
    });

    return res.json();
}

async function deleteFeedingById(itemId, feedingId, token) {
    const res = await fetch(getConfig().url + `/api/item/` + itemId +`/feeding-protocol/` + feedingId + `/delete` , {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type"  : "application/json",
            Authorization    : "BEARER " + token,
        },
    });

    return res.json();
}

export {getFeedingProtocolByItemId, deleteFeedingById}