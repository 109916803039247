import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip} from "@mui/material";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCancel, faCheck} from "@fortawesome/free-solid-svg-icons";

const DialogCustom = ({openDialog, title, message, handleClose, handleAction, actionButton, cancelButton, noButtons}) => {

return (
    <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {title}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {message}
            </DialogContentText>
        </DialogContent>
        {!noButtons ?
            <DialogActions>
                <Tooltip title={cancelButton}>
                    <button className="btn btn-primary" onClick={handleClose}>
                        <FontAwesomeIcon icon={faCancel} />
                    </button>
                </Tooltip>
                <Tooltip title={actionButton}>
                    <button className="btn btn-secondary" onClick={handleAction} autoFocus>
                        <FontAwesomeIcon icon={faCheck} />
                    </button>
                </Tooltip>
        </DialogActions>
        : null}
    </Dialog>
);
}

export default DialogCustom;

