import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAuth0} from "@auth0/auth0-react";
import {Skeleton, Tooltip} from "@mui/material";
import {deleteUserByUserId, getAuth0UserBy} from "../services/UserService";
import CustomDataGrid from "../utils/DataGrid";
import moment from "moment";
import {CardLink} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import DialogCustom from "../components/DialogCustom";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

const AdminUser = ({handleMessage}) => {
    const {t} = useTranslation();
    const {user, getAccessTokenSilently} = useAuth0();
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const roles = user['https://myroles.com/roles'];
    const [userToDelete, setUserToDelete] = useState(0);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const navigate = useNavigate();


    if (!roles.find((element) => element === "Admin")) {
        navigate("/");
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const accessToken = await getAccessTokenSilently();
            let response = await getAuth0UserBy(accessToken);
            setUsers(response?.users);
            setLoading(false);
        }

        fetchData().catch(r => {
            console.log(r.message)
            setLoading(false)
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleDeleteDialog = (id) => {
        setOpenDeleteDialog(true);
        setUserToDelete(id);
    };

    const handleDeleteClose = () => {
        setOpenDeleteDialog(false);
    };

    const deleteUser = async () => {
        setLoading(true);
        setOpenDeleteDialog(false);
        const accessToken = await getAccessTokenSilently();
        let result = await deleteUserByUserId(userToDelete, accessToken);
        if (result?.status === 400) {
            toast.error(t("Something went wrong, please try again later"));
        }
        if (result.successful) {
            let accessToken = await getAccessTokenSilently();
            let response = await getAuth0UserBy(accessToken);
            setUsers(response?.users);
        }
        setLoading(false);
    }

    const rows: GridRowsProp = [];

    if (users && users?.length > 0) {
        users?.map((row, index) => (
            rows[index] = {
                "id": row.user_id,
                "col2": row.family_name,
                "col3": row.given_name,
                "col4": row.email,
                "col5": moment(row.last_login).format("YYYY-MM-DD H:m:s"),
                "col6": moment(row.created_at).format("YYYY-MM-DD H:m:s"),
                "col7": row.user_id
            }
        ));
    }
    const columns: GridColDef[] = [
        {field: 'id', headerName: t("User Id"), width: 200, editable: false},
        {field: 'col2', headerName: t("family_name"), width: 150, editable: false},
        {field: 'col3', headerName: t("given_name"), width: 150, editable: false, sortable: false},
        {field: 'col4', headerName: t("email"), width: 150, editable: false, sortable: false},
        {field: 'col5', headerName: t("last_login"), width: 200, editable: false, sortable: false},
        {field: 'col6', headerName: t("created_at"), width: 200, editable: false, sortable: false},
        {
            field: 'col7',
            headerName: t("Actions"),
            width: 100,
            editable: false,
            sortable: false,
            renderCell: (params: id) => (
                <>
                    <CardLink>
                        <Tooltip title={t("Delete User")} disableFocusListener>
                            <button
                                onClick={() => handleDeleteDialog(params.value)}
                                className="btn btn-secondary">
                                <FontAwesomeIcon
                                    icon={faTrash}></FontAwesomeIcon>
                            </button>
                        </Tooltip>
                    </CardLink>
                </>
            )
        }

    ];

    return (
        <div className="row">
            <h1 className="mb-3 mb-lg-5">{t("View Users")}</h1>
            <div className="mb-3 mb-lg-5">
                <div className="card h-100">
                    <div className="card-body">
                        {loading ?
                            <>
                                <div className="row">
                                    <div className="col-md-12">
                                        <Skeleton animation="wave"/>
                                        <Skeleton animation="wave"/>
                                        <Skeleton animation="wave"/>
                                        <Skeleton animation="wave"/>
                                        <Skeleton animation="wave"/>
                                        <Skeleton animation="wave"/>
                                        <Skeleton animation="wave"/>
                                        <Skeleton animation="wave"/>
                                    </div>
                                </div>
                            </>
                            : null}
                        {!loading ?
                            <>
                                <div className="row">
                                    <div className="col-md-12">
                                        {rows.length === 0 ?
                                            <div style={{height: 300, width: '100%'}}>
                                                <CustomDataGrid rows={rows} columns={columns}/>
                                            </div>
                                            : <CustomDataGrid rows={rows} columns={columns}/>
                                        }
                                    </div>
                                </div>
                            </>
                            : null}
                    </div>
                </div>
            </div>
            <DialogCustom
                handleClose={handleDeleteClose}
                handleAction={deleteUser}
                message={t("ToDeleteTheSelectedUser")}
                title={t("AreYouSure?")}
                actionButton={t("Delete")}
                cancelButton={t("Cancel")}
                openDialog={openDeleteDialog}
            />
        </div>
    );
}
export default AdminUser;
